import { UpdateCartOptionsInput } from '../types';

export const updateCartOptionsFormData = (options): UpdateCartOptionsInput => {
	const baseData = {
		cart_id: options.cart_id,
		bee_customer_id: options.bee_customer_id,
	} as UpdateCartOptionsInput;

	if (Object.prototype.hasOwnProperty.call(options, 'seller_id') && options.seller_id) {
		baseData.seller_id = options.seller_id;
	} else {
		baseData.seller_id = null;
	}

	if (Object.prototype.hasOwnProperty.call(options, 'category_id') && options.category_id) {
		baseData.category_id = options.category_id;
	} else {
		baseData.category_id = null;
	}

	if (Object.prototype.hasOwnProperty.call(options, 'warranty_id') && options.warranty_id) {
		baseData.warranty_id = options.warranty_id;
	} else {
		baseData.warranty_id = null;
	}

	if (Object.prototype.hasOwnProperty.call(options, 'project') && options.project) {
		baseData.project = options.project;
	} else {
		baseData.project = null;
	}

	if (Object.prototype.hasOwnProperty.call(options, 'customer_order_number') && options.customer_order_number) {
		baseData.customer_order_number = options.customer_order_number;
	} else {
		baseData.customer_order_number = null;
	}

	if (Object.prototype.hasOwnProperty.call(options, 'country_id') && options.country_id) {
		baseData.country_id = options.country_id;
	} else {
		baseData.country_id = null;
	}

	if (Object.prototype.hasOwnProperty.call(options, 'location') && options.location) {
		baseData.location = options.location;
	} else {
		baseData.location = null;
	}

	if (Object.prototype.hasOwnProperty.call(options, 'is_corrosive_environment') && options.requested_delivery_date) {
		baseData.is_corrosive_environment = options.is_corrosive_environment;
	} else {
		baseData.is_corrosive_environment = 0;
	}

	if (Object.prototype.hasOwnProperty.call(options, 'requested_delivery_date') && options.requested_delivery_date) {
		baseData.requested_delivery_date = options.requested_delivery_date;
	} else {
		baseData.requested_delivery_date = null;
	}

	if (Object.prototype.hasOwnProperty.call(options, 'our_reference_id') && options.our_reference_id) {
		baseData.our_reference_id = options.our_reference_id;
	} else {
		baseData.our_reference_id = null;
	}

	if (Object.prototype.hasOwnProperty.call(options, 'customer_reference_id') && options.customer_reference_id) {
		baseData.customer_reference_id = options.customer_reference_id;
	} else {
		baseData.customer_reference_id = null;
	}

	if (Object.prototype.hasOwnProperty.call(options, 'approved_at') && options.approved_at) {
		baseData.approved_at = options.approved_at;
	} else {
		baseData.approved_at = null;
	}

	if (Object.prototype.hasOwnProperty.call(options, 'approved_by') && options.approved_by) {
		baseData.approved_by = options.approved_by;
	} else {
		baseData.approved_by = null;
	}

	if (Object.prototype.hasOwnProperty.call(options, 'additional_info') && options.additional_info) {
		baseData.additional_info = options.additional_info;
	} else {
		baseData.additional_info = null;
	}

    if (Object.prototype.hasOwnProperty.call(options, 'dimming') && options.dimming) {
        baseData.dimming = options.dimming;
    } else {
        baseData.dimming = null;
    }

	return baseData;
};