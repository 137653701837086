import { gql } from 'graphql-request';

export default gql`query($filter: CartsFilterInput) {
    carts(filter: $filter) {
        items {
            id
            bee_customer_id
            project
            is_active
        }
    }
}`;