import { SearchData } from './useFacet';

export const getSliders = () => {
	return new Set(['luminous_flux', 'power']);
};

const buildBreadcrumbsList = (rootCat, bc) => {
	const newBc = [...bc, {
		text: rootCat.name,
		link: rootCat.slug,
	}];
	return rootCat.parent ? buildBreadcrumbsList(rootCat.parent, newBc) : newBc;
};

export const buildBreadcrumbs = (rootCat) => buildBreadcrumbsList(rootCat, [])
	.reverse()
	.reduce(
		(prev, curr, index) => ([
			...prev,
			{
				...curr,
				link: `${prev[index - 1]?.link || ''}/${curr.link}`,
			}]),
		[],
	);

const filterFacets = (criteria) => (f) => (criteria ? criteria.includes(f.attribute_code) : true);

const getFacetTypeByCode = (code) => {
	if (code === 'cooling_fins') {
		return 'checkbox';
	}

	if (code.includes('luminous_flux') || code.includes('power')) {
		return 'slider';
	}

	return 'button';
};

const createFacetsFromOptions = (facets, filters, facet) => {
	const options = facet.options || [];

	const selectedList = filters && filters[facet.attribute_code] ? filters[facet.attribute_code] : [];
	return options
		.map(({
			label,
			value,
			count,
		}) => ({
			type: getFacetTypeByCode(facet.attribute_code),
			id: label,
			attrName: label,
			value,
			selected: selectedList.includes(value),
			count,
		}));
};

export const reduceForFacets = (facets, filters) => (prev, curr) => ([
	...prev,
	...createFacetsFromOptions(facets, filters, curr),
]);

export const reduceForGroupedFacets = (facets, filters) => (prev, curr) => ([
	...prev,
	{
		id: curr.attribute_code,
		label: curr.label,
		options: createFacetsFromOptions(facets, filters, curr),
	},
]);

export const buildFacets = (searchData: SearchData, reduceFn, criteria?: string[]) => {
	if (!searchData.data) {
		return [];
	}

	const {
		data: { availableFilters: facets },
		input: { filters },
	} = searchData;

	return facets?.filter(filterFacets(criteria)).reduce(reduceFn(facets, filters), []);
};
