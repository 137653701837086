import { gql } from 'graphql-request';

export default gql`
    query ($filter: CableConfiguratorFilterInput) {
        cableConfigurator(filter: $filter) {
            cable_code
            cable_code_object
            core_count
            cross_sectional_area
            length
            quantity
            core_count_object
            cross_sectional_area_object
            price
            model_name
            description
            article
        }
    }
`;