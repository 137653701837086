import { CategoryTree } from '~/modules/catalog/category/types';
import { computed, ComputedRef, Ref, ref, useRoute } from '@nuxtjs/composition-api';
import { useCategoryStore } from '~/stores/category';
import { findActiveCategory } from '~/modules/catalog/category/helpers';

export const useCategory = () => {
	const loading = ref(false);
	const categoryStore = useCategoryStore();
	const route = useRoute();
	const { path } = route.value;
	const categoryTree: ComputedRef<CategoryTree> = computed(() => categoryStore.categories);

	const loadCategoryTree = async () => {
		loading.value = true;
		await categoryStore.load();
		loading.value = false;
	}

	const activeCategory = computed(() => {
		const urlPathToFind = path
			.replace(/(\/[a-z]+\/(products)\/)|(\/[a-z]+\/(product-configurator)\/)/gi, '');
		return categoryTree.value === null ? null : findActiveCategory(categoryTree.value, urlPathToFind);
	});

	const lastVisitedCategoryUrlPath = computed(() => {
		return categoryStore.getLastVisitedCategoryUrlPath;
	});

	const hasProductConfigurator = computed({
		get: () => activeCategory.value?.products?.items?.find(product => product.access_configurator_from.includes('Product selector')),
		set: (newHasProductConfigurator) => hasProductConfigurator.value = newHasProductConfigurator
	});

	return {
		activeCategory,
		categoryTree,
		lastVisitedCategoryUrlPath,
		loading,
		loadCategoryTree,
		hasProductConfigurator
	};
}

export default useCategory;