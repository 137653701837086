import { Middleware } from '@nuxt/types';

const middleware: Middleware = ({ app: { $vsf, localePath }, redirect }) => {
	const isMember = $vsf.$magento.config.state.getIsMember();
	const isAcceptedTerms = $vsf.$magento.config.state.getIsAcceptedTerms();

	if (isMember && !isAcceptedTerms) {
		redirect(localePath('/members/terms-conditions'));
	}
};

export default middleware;