import { Label, PageInfo } from '~/modules/vizulo/labels/types';
import useApi from '~/composables/useApi';
import labelsGql from '~/modules/vizulo/labels/labels.gql';
import { Logger } from '~/helpers/logger';
import { ref } from '@nuxtjs/composition-api';

export interface LabelsOutput {
    total_count: number,
    pageInfo: PageInfo,
    items: Array<Label>,
}

export const useLabels = () => {
	const { query } = useApi();
	const loadingLabels = ref(false);
	const labels = ref([]);
    
	const getLabels = async (searchParams): Promise<LabelsOutput> => {
		loadingLabels.value = true;
		const { data } = await query<{ labels: LabelsOutput }>(labelsGql, searchParams);
		Logger.debug('Labels/Response', data);

		labels.value = data?.labels?.items;

		loadingLabels.value = false
        
		return {
			items: data?.labels?.items,
			total_count: data?.labels?.total_count,
			pageInfo: data?.labels?.pageInfo
		}
	}

	return {
		loadingLabels,
		getLabels,
		labels
	}
}

export default useLabels;