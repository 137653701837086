import { VacanciesPost } from '../types';
import { AgnosticPagination } from '~/composables/types';
import { addBasePath } from '~/helpers/addBasePath';
import {
    useContext,
} from '@nuxtjs/composition-api';

export const getSlug = (vacancy: VacanciesPost): string => {
    let url = vacancy?.url_key ? `/vacancies/${vacancy.url_key}` : '';
    return url;
};

export const getShareText = (vacancy: VacanciesPost): string => {
    const { app } = useContext();
    let name = vacancy?.name ? vacancy.name : '';
    let slug = app.localePath(getSlug(vacancy));

    return encodeURI(name + ' ' + slug);
};

export const getEncodedName = (vacancy: VacanciesPost): string => {
    let name = vacancy?.name ? vacancy.name : '';
    return encodeURI(name);
};

export const getEncodedSlug = (vacancy: VacanciesPost): string => {
    const { app } = useContext();
    let slug = app.localePath(getSlug(vacancy));

    return encodeURI(slug);
};

const getPagination = (vacancies: any): AgnosticPagination => ({
    currentPage: vacancies?.pageInfo?.currentPage || 1,
    totalPages: vacancies?.pageInfo?.endPage || 1,
    totalItems: vacancies?.total_count || 0,
    itemsPerPage: vacancies?.pageInfo?.pageSize || 10,
    pageOptions: [10, 50, 100],
});

const vacanciesGetters = {
    getSlug,
    getShareText,
    getEncodedName,
    getEncodedSlug,
    getPagination,
};

export default vacanciesGetters;
