// eslint-disable-next-line import/no-extraneous-dependencies
import { useRoute, useRouter } from '@nuxtjs/composition-api';
import { CategoryTreeInterface, Category, AgnosticFacet } from '~/composables/types';

const nonFilters = new Set(['page', 'sort', 'term', 'itemsPerPage', 'per_page']);
const sliders = new Set(['luminous_flux', 'power']);

const reduceFilters = (query) => (prev, curr) => {
	const makeArray = Array.isArray(query[curr]) || nonFilters.has(curr);

	return {
		...prev,
		[curr]: makeArray ? query[curr] : [query[curr]],
	};
};

const useUiHelpers = () => {
	const route = useRoute();
	const router = useRouter();
	const { name, query, path } = route.value;

	const getSliders = () => {
		return sliders;
	};

	const getFiltersDataFromUrl = (onlyFilters) => Object.keys(query)
		.filter((f) => (onlyFilters ? !nonFilters.has(f) : nonFilters.has(f)))
	// eslint-disable-next-line unicorn/prefer-object-from-entries
		.reduce(reduceFilters(query), {});

	const getFacetsFromURL = () => ({
		filters: getFiltersDataFromUrl(true),
		itemsPerPage: Number.parseInt(query.itemsPerPage as string, 10) || 100,
		page: Number.parseInt(query.page as string, 10) || 1,
		sort: query.sort as string || '',
		term: query.term as string,
	});

	const changeSearchTerm = (term: string) => term;

	const getSearchTermFromUrl = () => ({
		page: Number.parseInt(query.page as string, 10) || 1,
		sort: query.sort || '',
		filters: getFiltersDataFromUrl(true),
		itemsPerPage: Number.parseInt(query.itemsPerPage as string, 10) || 10,
		term: query.term,
	});

	const getCatLink = (category: Category): string => `/products/${category.url_path}${category.url_suffix || ''}`;

	const getProductConfiguratorCatLink = (category: Category) => `/product-configurator/${category.url_path}${category.url_suffix || ''}`;

	const getAgnosticCatLink = (category: CategoryTreeInterface): string => `/products${category.slug}`;

	const changeSorting = async (sort: string) => {
		await router.push({ query: { ...query, sort } });
	};

	const changeFilters = async (filters: any) => {
		await router.push({
			query: {
				...filters
			}
		});
	};

	const changeItemsPerPage = async (itemsPerPage: number) => {
		await router.push({
			query: {
				...getFiltersDataFromUrl(false),
				itemsPerPage,
			},
		});
	};

	const setTermForUrl = async (term: string) => {
		await router.push({
			query: {
				...getFiltersDataFromUrl(false),
				term: term || undefined,
			},
		});
	};

	const isFacetColor = (facet: AgnosticFacet): boolean => facet.id === 'color';
	const isFacetLuminousFlux = (facet: AgnosticFacet): boolean => facet.id === 'luminous_flux';
	const isFacetLuminousEfficacy = (facet: AgnosticFacet): boolean => facet.id === 'luminous_efficacy';
	const isFacetPower = (facet: AgnosticFacet): boolean => facet.id === 'power';

	const isFacetCheckbox = (): boolean => false;

	const getUrlPath = () => path.replace(/\/[a-z]+\/(products)\//gi, '');

	return {
		getSliders,
		reduceFilters,
		getFacetsFromURL,
		getCatLink,
		getAgnosticCatLink,
		changeSorting,
		changeFilters,
		changeItemsPerPage,
		setTermForUrl,
		isFacetColor,
		isFacetLuminousFlux,
		isFacetLuminousEfficacy,
		isFacetPower,
		isFacetCheckbox,
		getSearchTermFromUrl,
		changeSearchTerm,
		getUrlPath,
		getProductConfiguratorCatLink
	};
};

export default useUiHelpers;
