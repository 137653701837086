import useApi from '~/composables/useApi';
import productsList from './productsList.gql';
import { Logger } from '~/helpers/logger';
import { ref, useContext } from '@nuxtjs/composition-api';
import { ProductDetailsQuery, ProductInterface, Products } from '~/modules/GraphQL/types';

export const useProductList = () => {
    const { query } = useApi();
    const loading = ref(false);
    
    const getProducts = async () => {
        loading.value = true;

        const { data } = await query<{ products: Products }>(productsList);
        const items = data.products?.items ?? [];

        loading.value = false;
        
        return items;
    };

    return {
        getProducts,
        loading,
    };
}

export default useProductList;