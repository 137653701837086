import { gql } from 'graphql-request';

export default gql`
    query approveCustomer(
        $filter: CustomerApproveInput,
    ) {
        approveCustomer(filter: $filter) {
            status
        }
    }
`;