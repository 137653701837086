import { defineStore } from 'pinia';
import { CategoryTree } from '~/modules/GraphQL/types';

interface CategoryState {
	rawCategories: CategoryTree[] | null,
	lastVisitedCategoryUrlPath: string
}

export const useCategoryStore = defineStore('category', {
	state: (): CategoryState => ({
		rawCategories: null,
		lastVisitedCategoryUrlPath: ''
	}),
	actions: {
		async load() {
			const { data } = await this.$nuxt.app.$vsf.$magento.api.categoryList();
			this.rawCategories = data?.categories.items ?? null;
		},
		async loadLastVisitedCategory(urlPath: string) {
			if (this.rawCategories) {
				this.lastVisitedCategoryUrlPath = urlPath;
			}
		}
	},
	getters: {
		categories(state) {
			return state.rawCategories !== null ? state.rawCategories[0] : null;
		},
		getLastVisitedCategoryUrlPath(state) {
			return state.lastVisitedCategoryUrlPath;
		}
	}
});
