import { gql } from 'graphql-request';

export default gql`
  query productsList($search: String = "", $filter: ProductAttributeFilterInput, $pageSize: Int = 10000, $currentPage: Int = 1, $sort: ProductAttributeSortInput) {
    products(search: $search, filter: $filter, pageSize: $pageSize, currentPage: $currentPage, sort: $sort) {
      items {
        uid
        sku
        name
        url_key
        url_rewrites {
          url
        }
        categories {
          uid
          name
          level
          url_suffix
          url_path
          breadcrumbs {
            category_name,
            category_url_path
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
      total_count
    }
  }
`;
