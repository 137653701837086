// plugins/tooltip.js
import Vue from 'vue'
import FloatingVue from 'floating-vue'

const config = {
    boundary: '.item',
    themes: {
        tooltip: {
            triggers: ['hover', 'focus', 'touch'],
            handleResize: true,
            html: true,
        },
    }
}

Vue.use(FloatingVue, config)