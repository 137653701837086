import { gql } from 'graphql-request';

export default gql`
    query priceLists(
        $filter: DataTableFilterInput
    ) {
        priceLists(filter: $filter) {
            product {
                uid
                __typename
                sku
                name
                product_type
                stock_status
                url_key
                url_rewrites {
                    url
                }
                small_image {
                    url
                    disabled
                    label
                    tags
                }
                categories {
                    uid
                    name
                    url_suffix
                    url_path
                    breadcrumbs {
                        category_name,
                        category_url_path
                    }
                }
            }
            description
            total
            page
            per_page
            data {
                a
                b
                c
                d
                e
                f
                g
                h
                i
                j
                k
                l
                m
                n
                o
                p
                q
                r
                s
                t
                u
                v
                w
                x
                y
                z
            }
            filtered_columns {
                label
                field
                attribute_code
                abbreviation
                description
                hidden
                values {
                    uid
                    label
                    is_active
                    url
                    description
                }
                min
                max
                step
            }
        }
    }
`;
