import { ref, useContext } from '@nuxtjs/composition-api';
import { UseGeoIP, UseGeoIPInterface } from '~/composables/useGeoIP/useGeoIP';
import axios from 'axios'

const useGeoIP: UseGeoIP = (): UseGeoIPInterface => {
	const { app: { $vsf } } = useContext();
	const loading = ref(false);
	const error = ref(null);
	const countryCode = ref('');

	const getIpAddress = async () => {
		return (await axios.get('https://api.ipify.org'))?.data;
	}

	const getCountryCode = async (): Promise<void> => {
		loading.value = true;

		try {
			const ip = await getIpAddress();

			const { data: { geoIP } } = await $vsf.$magento.api.geoIP(ip);
			countryCode.value = geoIP.country_id.toLowerCase();
		} catch (err) {
			error.value = err;
		} finally {
			loading.value = false;
		}
	};

	return {
		loading,
		error,
		countryCode,
		getIpAddress,
		getCountryCode
	}
}

export default useGeoIP;