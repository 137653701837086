import { UserGetters as BaseUserGetters } from '~/getters/types';
import { Customer } from '~/modules/GraphQL/types';

export const getFirstName = (user: Customer): string => (user ? user.firstname : '');

export const getLastName = (user: Customer): string => (user ? user.lastname : '');

export const getEmailAddress = (user: Customer): string => (user ? user.email : '');

export const getCompanyName = (user: Customer): string => (user ? user.company_name : '');

export const getCompanyAddress = (user: Customer): string => (user ? user.company_address : '');

export const getCompanyPosition = (user: Customer): string => (user ? user.company_position : '');

export const getTelephone = (user: Customer): string => (user ? user.telephone : '');

export const getTaxvat = (user: Customer): string => (user ? user.taxvat : '');

export const getFullName = (user: Customer): string => (user ? `${user.firstname} ${user.lastname}` : '');

const userGetters: BaseUserGetters<Customer> = {
    getFirstName,
    getLastName,
    getEmailAddress,
    getFullName,
    getCompanyName,
    getCompanyAddress,
    getCompanyPosition,
    getTaxvat,
    getTelephone,
};

export default userGetters;
