import { gql } from 'graphql-request';

export default gql`query($filter: ReferencesFilterInput, $pageSize: Int, $currentPage: Int) {
    references(filter: $filter, pageSize: $pageSize, currentPage: $currentPage) {
        total_count
        pageInfo {
            currentPage
            endPage
            hasNextPage
            hasPreviousPage
            pageSize
            startPage
        }
        items {
            entity_id
            name
            content
            image {
                horizontal
                vertical
                wide
            }
            application
            country_id
            country
            gallery {
                horizontal
                vertical
                wide
                original
            }
            products {
                __typename
                sku
                name
                luminous_efficacy_range
                luminous_efficacy_min
                luminous_efficacy_max
                luminous_flux_range
                luminous_flux_min
                luminous_flux_max
                power_range
                power_min
                power_max
                product_type
                url_key
                url_rewrites {
                    url
                }
                categories {
                    uid
                    name
                    level
                    url_suffix
                    url_path
                    breadcrumbs {
                        category_name,
                        category_url_path
                    }
                }
                small_image {
                    url
                    position
                    disabled
                    label
                }
            }
            url_key
            top
            map
            created_at
        }
    }
}`;