import { gql } from 'graphql-request';

export default gql`
    query assortment(
        $filter: AssortmentFilterInput
    ) {
        assortment(filter: $filter) {
            data {
                a
                b
                c
                d
                e
                f
                g
                h
                i
                j
                k
                l
                m
                n
                o
                p
                q
                r
                s
                t
                u
                v
                w
                x
                y
                z
            }
            filtered_columns {
                label
                field
                attribute_code
                abbreviation
                description
                hidden
                values {
                    uid
                    label
                    is_active
                    url
                    description
                }
                min
                max
                step
            }
        }
    }
`;