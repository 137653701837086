import { defineStore } from 'pinia';
import { Wishlist, Cart } from '~/modules/GraphQL/types';

const wishlist: Wishlist = { items_count: 0 };

const user: any = {};
const cart: Cart = {
	id: '',
	is_virtual: false,
	total_quantity: 0,
	bee_customer_id: null,
	seller_id: null,
	category_id: null,
	project: null,
	customer_order_number: null,
	country_id: null,
	location: null,
	is_corrosive_environment: null,
	warranty_id: null,
	requested_delivery_date: null,
	our_reference_id: null,
	customer_reference_id: null,
	approved_at: null,
	approved_by: null,
	additional_info: null,
	dimming: null,
	shipping_addresses: [],
	company_addresses: [],
};

const isLoggedIn: boolean = false;

interface CustomerState {
    wishlist: Wishlist,
    user: any,
    cart,
	isLoggedIn: boolean
}

export const useCustomerStore = defineStore('customer', {
	state: (): CustomerState => ({
		wishlist,
		user,
		cart,
		isLoggedIn
	}),
	actions: {
		setIsLoggedIn(isLoggedIn: boolean) {
			this.isLoggedIn = isLoggedIn;
		}
	}
});
