import { gql } from 'graphql-request';

export default gql`mutation updateCartOptions($UpdateCartOptionsInput: UpdateCartOptionsInput!) {
    updateCartOptions(
        input: $UpdateCartOptionsInput
    ) {
        cart_id
        bee_customer_id
        seller_id
        category_id
        project
        customer_order_number
        country_id
        location
        is_corrosive_environment
        warranty_id
        requested_delivery_date
        our_reference_id
        customer_reference_id
        approved_at
        approved_by
        additional_info
        dimming
        categories {
            id
            name
        }
        warranty {
            id
            name
        }
        countries {
            id
            full_name_locale
        }
        sellers {
            id
            name
            account_manager
        }
        our_references {
            id
            name
        }
        customer_references {
            id
            name
        }
    }
}`;