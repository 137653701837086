import { Middleware } from '@nuxt/types';

const middleware : Middleware = (context) => {
	if (!context.app.$vsf.$magento.config.state.getCustomerToken()) {
		const login = context.app.localePath('/login');
		context.redirect(login);
	}
};

export default middleware;
