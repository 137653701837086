import { ref, computed, useContext } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { Cart, CartItemInterface, ProductInterface, ProductPrice } from '~/modules/GraphQL/types';
import { UseCartInterface, UseCartErrors, CartsOutput } from '~/composables/useCart/useCart';
import { useCustomerStore } from '~/stores/customer';
import { loadCartCommand } from '~/composables/useCart/commands/loadCartCommand';
import { clearCartCommand } from '~/composables/useCart/commands/clearCartCommand';
import { loadTotalQtyCommand } from '~/composables/useCart/commands/loadTotalQtyCommand';
import { addItemCommand } from '~/composables/useCart/commands/addItemCommand';
import { removeItemCommand } from '~/composables/useCart/commands/removeItemCommand';
import { updateItemOptionsCommand } from '~/composables/useCart/commands/updateItemOptionsCommand';
import { applyCouponCommand } from '~/composables/useCart/commands/applyCouponCommand';
import { removeCouponCommand } from '~/composables/useCart/commands/removeCouponCommand';
import useUiNotification from '~/composables/useUiNotification';
import { useProduct } from '@/composables';
import setActiveCart from './setActiveCart.gql';
import removeCartMutation from './removeCart.gql';
import getPriceQuery from './getPrice.gql';
import carts from './carts.gql';
import useApi from '~/composables/useApi';

const useCart = <
  CART extends Cart,
  CART_ITEM extends CartItemInterface,
  PRODUCT extends ProductInterface>
	(): UseCartInterface<CART, CART_ITEM, PRODUCT> => {
	const loading = ref<boolean>(false);
	const error = ref<UseCartErrors>({
		getCarts: null,
		addItem: null,
		removeItem: null,
		updateItemOptions: null,
		load: null,
		clear: null,
		applyCoupon: null,
		removeCoupon: null,
		loadTotalQty: null,
		canAddToCart: null,
		setNewCart: null,
		removeCart: null,
		getPrice: null,
	});
	const { app } = useContext();
	const context = app.$vsf;
	const customerStore = useCustomerStore();
	const cart = computed<CART>(() => customerStore.cart);
	const apiState = context.$magento.config.state;
	const { send: sendNotification, notifications } = useUiNotification();
	const { getProductDetailsByCustomFilter } = useProduct();
	const { query, mutate } = useApi();

	/**
   * Check if user has permission to 'add edit order'
   *
   * @return boolean
   */
	const canAddToCart = computed(() => {
		if (!customerStore.isLoggedIn || !apiState.getIsMember()) {
			return false;
		}

		const permissions = apiState.getPermissions();

		return permissions && !!permissions.find(el => el.code == 'add edit order' && el.enabled);
	});

	/**
   * Assign new cart object
   * @param cartId
   *
   * @return void
   */
	const setNewCart = async (cartId: string, project?: string): Promise<void> => {
		Logger.debug('useCart.setNewCart');

		try {
			loading.value = true;

			if (cartId.length && cart.value.id != cartId) {
				const newCart = await mutate(setActiveCart, { cart_id: cartId, project: project });

				customerStore.$patch((state) => {
					state.cart = newCart;
				});
			}
		} catch (err) {
			error.value.setNewCart = err;
			Logger.error('useCart/setNewCart', err);
		} finally {
			loading.value = false;
		}
	};

	/**
   * Remove cart object
   * @param cartId
   *
   * @return void
   */
	const removeCart = async (cartId: string): Promise<void> => {
		Logger.debug('useCart.removeCart');

		try {
			loading.value = true;

			await mutate(removeCartMutation, { cart_id: cartId });

			sendNotification({
				id: Symbol('cart_deleted'),
				message: 'Project removed',
				type: 'success',
				icon: 'check',
				persist: false,
				title: 'Project removed',
			});
		} catch (err) {
			error.value.removeCart = err;
			Logger.error('useCart/removeCart', err);
		} finally {
			loading.value = false;
		}
	};

	/**
   * Return all customer carts
   *
   * @return array
   */
	const getCarts = async (beeCustomerId: string): Promise<CartsOutput> => {
		const filter = {
			bee_customer_id: {
				eq: beeCustomerId
			},
		};

		const { data } = await query<{ carts: CartsOutput }>(carts, { filter });
		const items = data?.carts.items ?? [];

		return {
			items: items
		};
	};

	/**
   * Assign new cart object
   * @param newCart
   *
   * @return void
   */
	const setCart = (newCart: CART): void => {
		Logger.debug('useCart.setCart', newCart);

		customerStore.$patch((state) => {
			state.cart = newCart;
		});
	};

	/**
   * Check if product is in the cart
   * @param product
   *
   * @return boolean
   */
	// TODO rework parameter {product} => product, wrapping obj is not necessary
	const isInCart = ({ product }: { product: PRODUCT }): boolean => !!cart.value?.items?.find(
		(cartItem) => cartItem?.product?.uid === product.uid,
	);

	const load = async ({ customQuery = {}, beeCustomerId = false, realCart = false } = { customQuery: { cart: 'cart' } }): Promise<void> => {
		Logger.debug('useCart.load');

		try {
			loading.value = true;

			if (!beeCustomerId) {
				beeCustomerId = customerStore.user.bee_customer_id;
			}

			const loadedCart = await loadCartCommand.execute(context, { customQuery, realCart, beeCustomerId });

			customerStore.$patch((state) => {
				state.cart = loadedCart;
			});
			error.value.load = null;
		} catch (err) {
			error.value.load = err;
			Logger.error('useCart/load', err);
		} finally {
			loading.value = false;
		}
	};

	const clear = async ({ customQuery } = { customQuery: { cart: 'cart' } }): Promise<void> => {
		Logger.debug('useCart.clear');

		try {
			loading.value = true;
			clearCartCommand.execute(context);

			const loadedCart = await loadCartCommand.execute(context, { customQuery });

			customerStore.$patch((state) => {
				state.cart = loadedCart;
			});
		} catch (err) {
			error.value.clear = err;
			Logger.error('useCart/clear', err);
		} finally {
			loading.value = false;
		}
	};

	const loadTotalQty = async (): Promise<void> => {
		Logger.debug('useCart.loadTotalQty');

		try {
			loading.value = true;
			const totalQuantity = await loadTotalQtyCommand.execute(context);

			customerStore.$patch((state) => {
				state.cart.total_quantity = totalQuantity;
			});
		} catch (err) {
			error.value.loadTotalQty = err;
			Logger.error('useCart/loadTotalQty', err);
		} finally {
			loading.value = false;
		}
	};

	const addItem = async ({
		product,
		quantity,
		configuration_options,
	}): Promise<void> => {
		Logger.debug('useCart.addItem', { product, quantity, configuration_options });

		try {
			loading.value = true;

			await load({ beeCustomerId: apiState.getSelectedCompany() });

			if (!product) {
				/*
                    if there is no product, maybe there are some data in options to find one
                */
				const sku = configuration_options.find(e => e.code == 'sku');
				const article = configuration_options.find(e => e.code == 'article');
				const model_name = configuration_options.find(e => e.code == 'model_name');
				const abbreviation = configuration_options.find(e => e.code == 'abbreviation');

				const searchParams = {
					filter: {
						sku: { eq: sku ? sku['value'] : null },
						article: { eq: article ? article['value'] : null },
						model_name: { eq: model_name ? model_name['value'] : null },
						abbreviation: { eq: abbreviation ? abbreviation['value'] : null }
					}
				}

				product = await getProductDetailsByCustomFilter(searchParams);

				if (!product) {
					return;
				}
			}

			const updatedCart = await addItemCommand.execute(
				context,
				{
					currentCart: cart.value,
					product,
					quantity,
					configuration_options,
				},
			);
			error.value.addItem = null;
			customerStore.$patch((state) => {
				state.cart = updatedCart;
			});

			sendNotification({
				id: Symbol('product_added'),
				message: `${product.name} has been successfully added to your cart.`,
				type: 'success',
				icon: 'check',
				persist: false,
				title: 'Product added',
			});
		} catch (err) {
			error.value.addItem = err;
			Logger.error('useCart/addItem', err);
		} finally {
			loading.value = false;
		}
	};

	const removeItem = async ({ product }) => {
		Logger.debug('useCart.removeItem', { product });

		try {
			loading.value = true;
			const updatedCart = await removeItemCommand.execute(
				context,
				{
					currentCart: cart.value,
					product,
				},
			);

			error.value.removeItem = null;
			customerStore.$patch((state) => {
				state.cart = updatedCart;
			});

			sendNotification({
				id: Symbol('product_removed'),
				message: `${product.product.name} has been successfully removed from your cart.`,
				type: 'success',
				icon: 'check',
				persist: false,
				title: 'Product removed',
			});
		} catch (err) {
			error.value.removeItem = err;
			Logger.error('useCart/removeItem', err);
		} finally {
			loading.value = false;
		}
	};

	const updateItemOptions = async ({
		product,
		options,
		customQuery = { updateCartItems: 'updateCartItems' },
	}): Promise<void> => {
		Logger.debug('useCart.updateItemOptions', {
			product,
			options,
		});

		try {
			loading.value = true;

			const updatedCart = await updateItemOptionsCommand.execute(
				context,
				{
					currentCart: cart.value,
					product,
					options,
					customQuery,
				},
			);

			error.value.updateItemOptions = null;

			customerStore.$patch((state) => {
				state.cart = updatedCart;
			});
		} catch (err) {
			error.value.updateItemOptions = err;
			Logger.error('useCart/updateItemOptions', err);
		} finally {
			loading.value = false;
		}
	};

	const applyCoupon = async ({
		couponCode,
		customQuery,
	}): Promise<void> => {
		Logger.debug('useCart.applyCoupon');

		try {
			loading.value = true;
			const { updatedCart } = await applyCouponCommand.execute(
				context,
				{
					currentCart: cart.value,
					couponCode,
					customQuery,
				},
			);

			error.value.applyCoupon = null;

			customerStore.$patch((state) => {
				state.cart = updatedCart;
			});
		} catch (err) {
			error.value.applyCoupon = err;
			Logger.error('useCart/applyCoupon', err);
		} finally {
			loading.value = false;
		}
	};

	const removeCoupon = async ({
		customQuery,
	}): Promise<void> => {
		Logger.debug('useCart.removeCoupon');

		try {
			loading.value = true;
			const { updatedCart } = await removeCouponCommand.execute(
				context,
				{
					currentCart: cart.value,
					customQuery,
				},
			);

			error.value.removeCoupon = null;

			customerStore.$patch((state) => {
				state.cart = updatedCart;
			});

			loading.value = false;
		} catch (err) {
			error.value.removeCoupon = err;
			Logger.error('useCart/removeCoupon', err);
		} finally {
			loading.value = false;
		}
	};

	const getPrice = async (article: string): Promise<ProductPrice> => {
		Logger.debug('useCart.getPrice');
		loading.value = true;

		const { data } = await query<{ getPrice: ProductPrice }>(getPriceQuery, { article: article });
		const price = data?.getPrice;
		loading.value = false;

		return price;
	};

	return {
		setCart,
		cart,
		loadTotalQty,
		isInCart,
		addItem,
		load,
		removeItem,
		clear,
		updateItemOptions,
		applyCoupon,
		removeCoupon,
		loading,
		error,
		canAddToCart,
		getCarts,
		setNewCart,
		removeCart,
		getPrice
	};
};

export default useCart;
