import useApi from '~/composables/useApi';
import { CablesOutput, CableConfiguratorSearchParams, CableConfiguratorOutput } from './types';
import cables from './cables.gql';
import cableConfigurator from './cableConfigurator.gql';
import { useContext, ref } from '@nuxtjs/composition-api';
import { Maybe, DataTableSearchParams } from '~/modules/GraphQL/types';

export const useCables = () => {
	const { query } = useApi();
	const { app } = useContext();
    const cablesLoading = ref(false);

    const getList = async (params): Promise<Maybe<CablesOutput>> => {
        const searchParams: DataTableSearchParams = {
            filter: {
                data_filter: {
                    eq: JSON.stringify(params)
                }
            }
        };

        cablesLoading.value = true;
        const { data } = await query<{ cables: CablesOutput }>(cables, searchParams);
        cablesLoading.value = false;

        return data?.cables;
    };

    const getConfigurator = async (searchParams: CableConfiguratorSearchParams): Promise<Maybe<CableConfiguratorOutput>> => {
        cablesLoading.value = true;
        const { data } = await query<{ cableConfigurator: CableConfiguratorOutput }>(cableConfigurator, searchParams);
        cablesLoading.value = false;

        return data?.cableConfigurator;
    };

	return {
		getList,
        getConfigurator,
        cablesLoading
	};
}

export default useCables;
