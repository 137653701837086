import { gql } from 'graphql-request';

export default gql`query($article: String!) {
    getPrice(article: $article) {
        regular_price {
            value
            currency
        }
        final_price {
            value
            currency
        }
        discount {
            percent_off
            amount_off
        }
    }
}`;