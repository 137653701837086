import { Logger } from '~/helpers/logger';
import { ref, useContext, useRoute } from '@nuxtjs/composition-api';
import { getProductListCommand } from '~/composables/useProduct/commands/getProductListCommand';
import { getProductDetailsCommand } from '~/composables/useProduct/commands/getProductDetailsCommand';
import { ProductsListQuery, ProductDetailsQuery, ProductExtendedData, ProductInterface } from '~/modules/GraphQL/types';
import productDataByOrder from '~/customQueries/productDataByOrder';
import productByData from '~/customQueries/productByData';
import useApi from '~/composables/useApi';

export const useProduct = (id?: string) => {
	const loading = ref(false);
	const error = ref({
		search: null,
		productData: null
	});

	const route = useRoute();
	const { params, query: routeQuery } = route.value;
	const { query } = useApi();

	const { app } = useContext();
	const context = app.$vsf;

	const getProductList = async (searchParams) => {
		Logger.debug('useProduct/getProductList', searchParams);
		let products : ProductsListQuery['products'] = null;

		try {
			loading.value = true;
			products = await getProductListCommand.execute(context, searchParams);
			error.value.search = null;
		} catch (err) {
			error.value.search = err;
			Logger.error('useProduct/search', err);
		} finally {
			Logger.debug('[Result]: ', products);
			loading.value = false;
		}

		return products;
	};

	const getProductDetails = async (searchParams): Promise<ProductDetailsQuery['products']> => {
		Logger.debug('useProduct/getProductDetails', searchParams);
		let products : ProductDetailsQuery['products'] = null;

		try {
			loading.value = true;
			products = await getProductDetailsCommand.execute(context, searchParams);
			error.value.search = null;
		} catch (err) {
			error.value.search = err;
			Logger.error(`useProduct/${id}/search`, err);
		} finally {
			loading.value = false;
			Logger.debug('[Result]: ', products);
		}

		return products;
	};

	const getExtendedDataFromOrder = async () => {
		const searchParams = {
			filter: {
				article: {
					eq: params.article
				},
				monitor_order_no: {
					eq: routeQuery.so
				},
				serial_number: {
					eq: routeQuery.sn
				}
			}
		}

		Logger.debug('useProduct/getExtendedDataFromOrder', searchParams);
		let productData: ProductExtendedData = null;

		try {
			loading.value = true;
			const { data } = await query<{ productDataByOrder: ProductExtendedData }>(productDataByOrder, searchParams);
			productData = data?.productDataByOrder;
			error.value.productData = null;
		} catch (e) {
			const jsonError = JSON.parse(JSON.stringify(e));
			error.value.productData = jsonError.response?.errors[0]?.message;
			Logger.error('useProduct/getExtendedDataFromOrder', e);
		} finally {
			loading.value = false;
		}

		return productData;
	}

	const getProductDetailsByCustomFilter = async (searchParams): Promise<ProductInterface> => {
		Logger.debug('useProduct/getProductDetailsByCustomFilter', searchParams);
		let product : ProductInterface = null;

		try {
			loading.value = true;

			const { data } = await query<{ productByData: ProductInterface }>(productByData, searchParams);
			product = data.productByData;

			error.value.search = null;
		} catch (err) {
			error.value.search = err;
			Logger.error('getProductDetailsByCustomFilter', err);
		} finally {
			loading.value = false;
			Logger.debug('[Result]: ', product);
		}

		return product;
	};

	return {
		error,
		getProductList,
		getProductDetails,
		getExtendedDataFromOrder,
		getProductDetailsByCustomFilter,
		loading
	}
};

export default useProduct;
