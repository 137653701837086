import { MemberPermissionsInput, PermissionInput } from '../types';

export const generatePermissionsFormData = (form): MemberPermissionsInput => {
    const baseData = {
        member_id: form.member_id,
        group_id: form.group_id,
    } as MemberPermissionsInput;

    if (Object.prototype.hasOwnProperty.call(form, 'permissions')) {
        let permissions = [];

        form.permissions.forEach((opt) => {
            permissions.push({
                code: opt.code,
                enabled: opt.enabled,
            } as PermissionInput);
        });

        baseData.permissions = permissions;
    } else {
        baseData.permissions = [];
    }

    return baseData;
};