const middleware = {}

middleware['has-access-to-checkout'] = require('../middleware/has-access-to-checkout.ts')
middleware['has-access-to-checkout'] = middleware['has-access-to-checkout'].default || middleware['has-access-to-checkout']

middleware['has-permission'] = require('../middleware/has-permission.ts')
middleware['has-permission'] = middleware['has-permission'].default || middleware['has-permission']

middleware['is-accepted-terms'] = require('../middleware/is-accepted-terms.ts')
middleware['is-accepted-terms'] = middleware['is-accepted-terms'].default || middleware['is-accepted-terms']

middleware['is-authenticated'] = require('../middleware/is-authenticated.ts')
middleware['is-authenticated'] = middleware['is-authenticated'].default || middleware['is-authenticated']

middleware['is-marketing-materials'] = require('../middleware/is-marketing-materials.ts')
middleware['is-marketing-materials'] = middleware['is-marketing-materials'].default || middleware['is-marketing-materials']

middleware['is-member'] = require('../middleware/is-member.ts')
middleware['is-member'] = middleware['is-member'].default || middleware['is-member']

middleware['is-staging'] = require('../middleware/is-staging.ts')
middleware['is-staging'] = middleware['is-staging'].default || middleware['is-staging']

export default middleware
