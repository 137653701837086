import { gql } from 'graphql-request';

export default gql`
    query productDataByOrder($filter: ProductExtendedDataInput) {
        productDataByOrder(filter: $filter) {
            abbreviation
            product_version
            color {
                id
                code
                number
                name
                hex_code
                stock_code
                is_default
            }
            order_info_params
            order_info_text
        }
    }
`;