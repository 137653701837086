import { gql } from 'graphql-request';

export default gql`
    query {
        academyCategories {
            items {
                id
                name
                url
                items {
                    id
                    name
                    slug
                    url
                    description
                    category
                    image
                    files {
                        name
                        path
                    }
                    created_at
                }
            }
        }
    }
`;