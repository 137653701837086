import {
	AgnosticAttribute,
	AgnosticBreadcrumb,
	AgnosticMediaGalleryItem,
	AgnosticPrice,
	Category,
	Product
} from '~/composables/types';
import { ProductGetters as ProductGettersBase } from '~/getters/types';
import { BundleProduct, CategoryInterface, CategoryTree, GroupedProduct } from '~/modules/GraphQL/types';
import { htmlDecode } from '~/helpers/htmlDecoder';
import categoryGetters from './categoryGetters';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import { useContext } from '@nuxtjs/composition-api';

type ProductVariantFilters = any;

export const getName = (product: Product): string => {
	if (!product) {
		return '';
	}

	return htmlDecode(product.name);
};

export const getSlug = (product: Product, category?: Category, isProductConfigurator = false): string => {
	const rewrites = product?.url_rewrites;
	if (!rewrites || rewrites.length === 0) {
		return;
	}

	const cat = category ?? product.categories?.find(category => {
		return isProductConfigurator ? category.level === 3 : category.level === 2;
	});

	let relativeUrl = [
		isProductConfigurator ? cat?.url_path : cat?.url_path.split('/')[0],
		product?.url_key
	].join('/');

    if(relativeUrl.charAt(0) === '/') {
        relativeUrl = relativeUrl.slice(1);
    }

	const rewrite = relativeUrl ? rewrites?.find(rewrite => rewrite.url === relativeUrl) : null;
	return rewrite?.url;
};

export const getPrice = (product: Product): AgnosticPrice => {
	let regular = 0;
	let special = null;

	if (product?.price_range) {
		regular = product.price_range.minimum_price.regular_price.value;
		const final = product.price_range.minimum_price.final_price.value;

		if (final < regular) {
			special = final;
		}
	}

	return {
		regular,
		special
	};
};

export const getGallery = (product: Product): AgnosticMediaGalleryItem[] => {
	const images = [];

	for (let url of getImage360(product)) {
		images.push({
			small: url.split('?')[0] + '?profile=VIZULO_default&thumb&w=60',
			normal: url,
			big: url,
			sirv: true
		});
	}

	if (!product?.media_gallery) {
		return images;
	}

	product.media_gallery.sort(function (a, b) {
		return (a.position > b.position) ? 1 : -1;
	});

	// eslint-disable-next-line no-restricted-syntax
	for (const galleryItem of product.media_gallery) {
		if (galleryItem.disabled || (product.hero_image && product.hero_image.url == galleryItem.url)) {
			continue;
		}

		images.push({
			small: galleryItem.url,
			normal: galleryItem.url,
			big: galleryItem.url,
			sirv: false,
			tags: galleryItem.tags
		});
	}

	return images;
};

export const getTopImage = (product: Product): any => {
    if (!product || !product.top_image) {
        return false;
    }

    return product.top_image;
};

export const getVideo = (product: Product): string => {
	if (!product || !product.video) {
		return null;
	}

	return product.video;
};

export const getImage360 = (product: Product): any => {
	if (!product || !product.image_360) {
		return [];
	}

	return product.image_360.split(',');
};

export const getCoverImage = (product: Product): string => {
	if (!product || !product.image) {
		return null;
	}

	return product.image.url;
};

export const getProductThumbnailImage = (product: Product): string => {
	if (!product || !product.thumbnail) {
		return null;
	}

	return product.thumbnail.url;
};

export const getProductSmallImage = (product: Product): string => {
	if (!product || !product.small_image) {
		return null;
	}

	return product.small_image.url;
};

export const getProductHeroImage = (product: Product): any => {
	if (!product || isEmpty(product)) {
		return null;
	}

	if (product.hero) {
		return {
			'type': product.hero.includes('sirv') ? 'sirv' : 'img',
			'url': product.hero
		};
	}

	if (product.hero_image && product.hero_image.url && !product.hero_image.url.includes('placeholder')) {
		return {
			'type': product.hero_image.url.includes('sirv') ? 'sirv' : 'img',
			'url': product.hero_image.url
		};
	}

	return null;
};

export const getFormattedValue = (text: any): string => {
	return text.replace(/\(\(/g, '<sup>').replace(/\)\)/g, '</sup>');
};

export const getFiltered = (products: Product[], filters: ProductVariantFilters | any = {}): Product[] => {
	if (!products) {
		return [];
	}

	return products;
};

export const getAttributes = (
	products: Product,
	_filterByAttributeName?: string[]
): Record<string, AgnosticAttribute | string> => {
	if (!products || !products?.configurable_options) {
		return {};
	}

	const attributes = {};
	const configurableOptions = products.configurable_options;

	// eslint-disable-next-line no-restricted-syntax
	for (const option of configurableOptions) {
		attributes[option.attribute_code] = {
			name: option.attribute_code,
			label: option.label,
			value: option.values.map((value) => {
				const obj = {};
				obj[value.value_index] = value.label;
				return obj;
			})
		} as AgnosticAttribute;
	}
	return attributes;
};

export const getAttributeSet = (product: Product): string => {
	if (!product || !product?.attribute_set) {
		return '';
	}

	return product.attribute_set;
};

export const getDescription = (product: Product): string => {
	if (!product || !product?.description) {
		return '';
	}

	return product.description.html;
};

export const getShortDescription = (product: Product): string => {
	if (!product || !product.short_description) {
		return '';
	}
	return product.short_description.html;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getCategoryIds = (product: Product): string[] => {
	const categoryIds = [];

	if (!product?.categories) {
		return categoryIds;
	}

	return product.categories.map((category) => category.uid);
};

export const getCategoriesBasedOnUrlPath = (product: Product, currentUrlPath: string): CategoryInterface[] | null => {
	if (product.categories?.length > 0) {
		const urlPathToFind = currentUrlPath.replace(/\/[a-z]+\/(products)\//gi, '')
			.split('/')[0];

		return product.categories.filter(category => category.url_path.includes(urlPathToFind));
	}

	return null;
}

export const getCategory = (product: Product, currentUrlPath: string, isProductConfigurator = false): CategoryInterface => {
	if (!product?.categories || product?.categories.length === 0) {
		return null;
	}

	const urlPathToFind = isProductConfigurator
		? currentUrlPath.replace(/\/[a-z]+\/(product-configurator)\//gi, '')
		: currentUrlPath.replace(/[a-z]+\/(products)\//gi, '').split('/')[0];

	return product?.categories?.find(category => {
		if (isProductConfigurator) {
			return category.url_path.includes(urlPathToFind) && category.url_path.split('/').length === 2;
		}

		return category.url_path === urlPathToFind;
	}) ?? null;
};

export const getId = (product: Product): string => product.uid;

export const getProductSku = (product: Product): string => product.sku;

// @ts-ignore
// eslint-disable-next-line no-underscore-dangle
export const getTypeId = (product: Product): string => product.__typename;

export const getFormattedPrice = (price: number) => {
	if (price === null) {
		return null;
	}

	// TODO get correct data from api
	const locale = 'en';
	const country = 'en';
	const currency = 'USD';

	return new Intl.NumberFormat(`${locale}-${country}`, {
		style: 'currency',
		currency
	}).format(price);
};

export const getBreadcrumbs = (product: any, category?: Category, productName?: string): AgnosticBreadcrumb[] => {
	let breadcrumbs = [];

	if (!product) {
		return breadcrumbs;
	}

	if (category) {
		breadcrumbs = categoryGetters.getBreadcrumbs(category) as AgnosticBreadcrumb[];
	}

	if (!isEmpty(product)) {
		breadcrumbs.push({
			text: getName(product),
			link: getProductLink(product, category) || ''
		});
	}

	return breadcrumbs;
};

export const getProductLink = (product: Product, category?: Category): string => {
	const slug = getSlug(product, category) || product.url_key;
	const sku = getProductSku(product);

	let seperatedLink = `/products/${slug}`.split('/');
    // TODO
    let name = slug.split('/').length == 2 ? slug.split('/')[1] : slug.split('/')[0];

	seperatedLink.splice(3, 1, `${sku}-${name}`);
	return seperatedLink.join('/');
}

export const getProductRelatedProduct = (product: any): Product[] => product?.related_products || [];

export const getProductUpsellProduct = (product: any): Product[] => product?.upsell_products || [];

export const getSwatchData = (swatchData: Product['configurable_options'][0]['values'][0]['swatch_data']): string | undefined => swatchData?.value;

const sortProduct = (a, b) => a.position - b.position;

// eslint-disable-next-line no-underscore-dangle
export const getGroupedProducts = (product: GroupedProduct & { __typename: string }): GroupedProduct['items'] | undefined => product.__typename === 'GroupedProduct' && product?.items?.sort(sortProduct);

// eslint-disable-next-line no-underscore-dangle
export const getBundleProducts = (product: BundleProduct & { __typename: string }): BundleProduct['items'] | undefined => product.__typename === 'BundleProduct' && product?.items?.sort(sortProduct);

export const getProductConfigurationLink = (product: Product, category?: Category) => {
	const slug = getSlug(product, category, true) || product.url_key;

	return `/product-configurator/${slug}`;
}

export const getProductUlLabel = (product: Product): any => {
    const { app } = useContext();

    if (product.is_ul) {
        return app.i18n.t('UL');
    }

    return false;
}

export const getProductLabel = (product: Product): any => {
    const { app } = useContext();

	if (product.new_from_date || product.new_to_date) {
		const currentDate = moment().set('hours', 0).set('minutes', 0).set('seconds', 0);

		if (currentDate.isBefore(product.new_from_date, 'day')) {
			return app.i18n.t('Coming soon');
		}

		if (currentDate.isBetween(product.new_from_date, product.new_to_date, 'dates', '[]')) {
			return app.i18n.t('New');
		}
	}

	return false;
}

export const getProductLuminousFlux = (product: Product) => {
    const { app } = useContext();

	if (!product) {
		return '';
	}

	let text = '';

	if (product.luminous_flux_min) {
		text += product.luminous_flux_min + ' - ' + product.luminous_flux_max + ' lm';
	} else if (product.luminous_flux_max) {
		text += app.i18n.t('Up to') + ' ' + product.luminous_flux_max + ' lm';
	}

	return text;
}

export const getProductEfficacy = (product: Product) => {
    const { app } = useContext();

	if (!product) {
		return '';
	}

	let text = '';

	if (product.luminous_efficacy_min) {
		text += product.luminous_efficacy_min + ' - ' + product.luminous_efficacy_max + ' lm/W';
	} else if (product.luminous_efficacy_max) {
		text += app.i18n.t('Up to') + ' ' + product.luminous_efficacy_max + ' lm/W';
	}

	return text;
}

export const getProductPower = (product: Product) => {
    const { app } = useContext();

	if (!product) {
		return '';
	}

	let text = '';

	if (product.power_min) {
		text += product.power_min + ' - ' + product.power_max + ' W';
	} else if (product.power_max) {
		text += app.i18n.t('Up to') + ' ' + product.power_max + ' W';
	}

	return text;
}

export const getProductInfo = (product: Product) => {
    const { app } = useContext();

	if (!product) {
		return '';
	}

	let text = '';

    if (product.luminous_flux_max) {
        text += app.i18n.t('Up to') + ' ' + product.luminous_flux_max + ' lm';
    }

	if (product.power_max) {
		if (text.length) {
			text += ' / ';
		} else {
            text += app.i18n.t('Up to') + ' ';
        }

		text += product.power_max + ' W';
	}

	return text;
}

export const getIsNewProduct = (product: Product) => {
	if (!product) {
		return false;
	}

	const currentDate = moment().set('hours', 0).set('minutes', 0).set('seconds', 0);

	return product.new_from_date && product.new_to_date
		? currentDate.isBetween(product.new_from_date, product.new_to_date, 'dates', '[]')
		: false;
}

export interface ProductGetters extends ProductGettersBase<Product, ProductVariantFilters, CategoryTree> {
	getCategoriesBasedOnUrlPath(product: Product, currentUrlPath: string): CategoryInterface[] | null
	getCategory(product: Product, currentUrlPath: string, isProductConfigurator?: boolean): CategoryInterface | null;
	getProductRelatedProduct(product: Product): Product[];
	getProductSku(product: Product): string;
	getProductThumbnailImage(product: Product): string;
	getProductSmallImage(product: Product): string;
	getProductUpsellProduct(product: Product): Product[];
	getShortDescription(product: Product): string;
	getSlug(product: Product, category?: Category): string;
    getProductLabel(product: Product): string | boolean;
    getProductUlLabel(product: Product): string | boolean;
    getProductPower(product: Product): string;
    getProductLuminousFlux(product: Product): string;
    getProductEfficacy(product: Product): string;
    getProductInfo(product: Product): string;
	getIsNewProduct(product: Product): boolean;
    getTypeId(product: Product): string;
	getAttributeSet(product: Product): string;
	getSwatchData(swatchData: Product['configurable_options'][0]['values'][0]['swatch_data']): string | undefined;
	getGroupedProducts(product: GroupedProduct): GroupedProduct['items'] | undefined;
	getBundleProducts(product: BundleProduct): BundleProduct['items'] | undefined;
	getProductLink(product: Product, category?: Category): string;
	getProductConfigurationLink(product: Product, category?: Category): string;
}

const productGetters: ProductGetters = {
	getAttributes,
	getBreadcrumbs,
	getCategoriesBasedOnUrlPath,
	getCategory,
	getCategoryIds,
	getCoverImage,
	getDescription,
	getFiltered,
	getFormattedPrice,
	getVideo,
	getGallery,
	getImage360,
	getProductHeroImage,
	getId,
	getName,
	getPrice,
	getProductRelatedProduct,
	getProductSku,
	getProductThumbnailImage,
	getProductSmallImage,
	getProductUpsellProduct,
	getShortDescription,
	getSlug,
	getTypeId,
	getAttributeSet,
	getSwatchData,
	getGroupedProducts,
	getBundleProducts,
	getProductLink,
	getFormattedValue,
	getProductConfigurationLink,
	getProductLabel,
    getProductUlLabel,
	getProductPower,
	getProductLuminousFlux,
	getProductEfficacy,
	getProductInfo,
	getIsNewProduct,
};

export default productGetters;
