import { Logger } from '~/helpers/logger';
import { Cart, UpdateCartItemsInput } from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';

export const updateItemOptionsCommand = {
    execute: async (context: VsfContext, {
        currentCart,
        product,
        options,
        customQuery = { updateCartItems: 'updateCartItems' },
    }) => {
        Logger.debug('[Magento]: Update product options on cart', {
            product,
            options,
            currentCart,
        });

        const updateCartParams: UpdateCartItemsInput = {
            cart_id: currentCart.id,
            cart_items: [
                {
                    cart_item_uid: product.uid,
                    options: JSON.stringify(options),
                },
            ],
        };

        const { data } = await context.$magento.api.updateCartItems(updateCartParams, customQuery);

        Logger.debug('[Result]:', { data });

        return data
            .updateCartItems
            .cart as unknown as Cart;
    },
};
