import { gql } from 'graphql-request';

export default gql`
    query($filter: AcademyFilterInput) {
        academy(filter: $filter) {
            id
            name
            slug
            url
            description
            category
            image
            files {
                name
                path
            }
            created_at
        }
    }
`;