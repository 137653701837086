import { gql } from 'graphql-request';

export default gql`
    query permissions {
        permissions {
            permissions {
                code
                name
                internal
            }
            member_groups {
                id
                name
                permissions {
                    code
                    name
                    enabled
                }
            }
            companies {
                id
                name
                managers {
                    id
                    account_manager
                    name
                }
            }
            members {
                id
                email
                company_id
                group_id
                name
                permissions {
                    code
                    name
                    custom
                    enabled
                }
            }
        }
    }
`;