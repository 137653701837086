import { News } from '../types';
import { AgnosticPagination } from '~/composables/types';

export const getSlug = (news: News): string => {
    let url = news?.url_key ? `/news/${news.url_key}-${news.entity_id}` : '';
    return url;
};

const getPagination = (news: any): AgnosticPagination => ({
    currentPage: news?.pageInfo?.currentPage || 1,
    totalPages: news?.pageInfo?.endPage || 1,
    totalItems: news?.total_count || 0,
    itemsPerPage: news?.pageInfo?.pageSize || 10,
    pageOptions: [10, 50, 100],
});

const newsGetters = {
    getSlug,
    getPagination,
};

export default newsGetters;
