import { Logger } from '~/helpers/logger';
import { AddProductsToCartInput } from '~/composables/useCart/useCart';
import {
	AddSimpleProductsToCartInput,
	AddConfigurableProductsToCartInput,
	AddDownloadableProductsToCartInput,
	AddVirtualProductsToCartInput,
	Cart,
} from '~/modules/GraphQL/types';
import { VsfContext } from '~/composables/context';

export const addItemCommand = {
	execute: async (
		context: VsfContext,
		{
			product,
			quantity,
			currentCart,
			configuration_options,
		},
	) => {
		const currentCartId = currentCart.id;

		Logger.debug('[Magento]: Add item to cart', { currentCartId, product, quantity, currentCart, configuration_options });

		if (!product) {
			return;
		}

		// @ts-ignore
		// eslint-disable-next-line no-underscore-dangle
		switch (product.__typename) {
			case 'SimpleProduct':
				const simpleCartInput: AddSimpleProductsToCartInput = {
					cart_id: currentCartId,
					cart_items: [
						{
							data:{
                                quantity,
                                sku: product.sku,
								configuration_options: configuration_options
							},
						},
					],
				};

				const simpleProduct = await context.$magento.api.addSimpleProductsToCart(simpleCartInput);

				Logger.debug('[Result]:', { data: simpleProduct });

				// eslint-disable-next-line consistent-return
				return simpleProduct
					.data
					.addSimpleProductsToCart
					.cart as unknown as Cart;

			case 'ConfigurableProduct':
				const cartItems = [
					{
						parent_sku: product.sku,
						data: {
							quantity,
							sku: product.configurable_product_options_selection?.variant?.sku || '',
							configuration_options,
						},
					},
				];

				const configurableCartInput: AddConfigurableProductsToCartInput = {
					cart_id: currentCartId,
					cart_items: cartItems,
				};

				const configurableProduct = await context.$magento.api.addConfigurableProductsToCart(configurableCartInput);

				Logger.debug('[Result]:', { data: configurableProduct });

				// eslint-disable-next-line consistent-return
				return configurableProduct
					.data
					.addConfigurableProductsToCart
					.cart as unknown as Cart;
			case 'BundleProduct':
				const createEnteredOptions = () =>
				// @ts-ignore
				// eslint-disable-next-line implicit-arrow-linebreak
					product.bundle_options.map((bundleOption) => ({
						...bundleOption,
						value: bundleOption.value.toString(),
					}));

				const bundleCartInput: AddProductsToCartInput = {
					cartId: currentCartId,
					cartItems: [
						{
							quantity,
							sku: product.sku,
							entered_options: createEnteredOptions(),
							configuration_options,
						},
					],
				};

				const bundleProduct = await context.$magento.api.addProductsToCart(bundleCartInput);

				Logger.debug('[Result]:', { data: bundleProduct });

				// eslint-disable-next-line consistent-return
				return bundleProduct
					.data
					.addProductsToCart
					.cart as unknown as Cart;
			case 'DownloadableProduct':
				const downloadableCartItems = [
					{
						data: {
							quantity,
							sku: product.sku,
							configuration_options,
						},
						downloadable_product_links: product.downloadable_product_links.map((dpl) => ({ link_id: dpl.id })),
					},
				];

				const downloadableCartInput: AddDownloadableProductsToCartInput = {
					cart_id: currentCartId,
					cart_items: downloadableCartItems,
				};

				const downloadableProduct = await context.$magento.api.addDownloadableProductsToCart(downloadableCartInput);

				Logger.debug('[Result DownloadableProduct]:', { data: downloadableProduct });

				// eslint-disable-next-line consistent-return
				return downloadableProduct
					.data
					.addDownloadableProductsToCart
					.cart as unknown as Cart;
			case 'VirtualProduct':
				const virtualCartInput: AddVirtualProductsToCartInput = {
					cart_id: currentCartId,
					cart_items: [
						{
							data: {
								quantity,
								sku: product.sku,
								configuration_options,
							},
						},
					],
				};
				const virtualProduct = await context.$magento.api.addVirtualProductsToCart(virtualCartInput);

				Logger.debug('[Result VirtualProduct]:', { data: virtualProduct });

				// eslint-disable-next-line consistent-return
				return virtualProduct
					.data
					.addVirtualProductsToCart
					.cart as unknown as Cart;
			default:
				// todo implement other options
				// @ts-ignore
				// eslint-disable-next-line no-underscore-dangle
				throw new Error(`Product Type ${product.__typename} not supported in add to cart yet`);
		}
	},
};
