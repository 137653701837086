import useApi from '~/composables/useApi';
import academyCategories from './academyCategories.gql';
import academyList from './academyList.gql';
import academy from './academy.gql';
import { ref } from '@nuxtjs/composition-api';
import { Maybe } from '~/modules/GraphQL/types';
import {
    AcademyCategoriesOutput,
    AcademyListOutput,
	Academy,
    AcademyListSearchParams,
    AcademyListFilterInput,
    AcademySearchParams,
	AcademyFilterInput,
} from './types';

export const useAcademy = () => {
	const { query } = useApi();
	const loading = ref(false);

    const getAcademyCategories = async (): Promise<Maybe<AcademyCategoriesOutput>> => {
        loading.value = true;

        const { data } = await query<{ academyCategories: AcademyCategoriesOutput }>(academyCategories);
        const items = data?.academyCategories.items ?? [];

        loading.value = false;

        return {
            items
        };
    };

    const getAcademyList = async (searchParams: AcademyListSearchParams): Promise<Maybe<AcademyListOutput>> => {
        loading.value = true;

        const { data } = await query<{ academyList: AcademyListOutput }>(academyList, searchParams);
        const items = data?.academyList.items ?? [];

        loading.value = false;

        return {
            items
        };
    };

	const getAcademy = async (searchParams: AcademySearchParams): Promise<Maybe<Academy>> => {
		loading.value = true;

		const { data } = await query<{ academy: Academy }>(academy, searchParams);
		const item = data?.academy ?? null;

        const id = item?.id;
        const name = item?.name;
        const slug = item?.slug;
        const url = item?.url;
        const description = item?.description;
        const category = item?.category;
        const image = item?.image;
        const files = item?.files;
        const created_at = item?.created_at;

		loading.value = false;

		return {
            id,
            name,
            slug,
            url,
            description,
            category,
            image,
            files,
            created_at
        };
	};

	return {
        loading,
        getAcademyCategories,
        getAcademyList,
		getAcademy
	};
}

export default useAcademy;