import { gql } from 'graphql-request';

export default gql`
    query cables(
        $filter: DataTableFilterInput
    ) {
        cables(filter: $filter) {
            total
            page
            per_page
            data {
                article
                cable_code
                wire_count
                cross_sectional_area
                length
                description
            }
            filtered_columns {
                label
                field
                attribute_code
                abbreviation
                hidden
                description
                values {
                    uid
                    label
                    is_active
                    url
                    description
                    relation {
                        type
                        value
                    }
                }
                min
                max
                step
            }
        }
    }
`;