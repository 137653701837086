import { Middleware } from '@nuxt/types';

const middleware: Middleware = ({ route, app, error }) => {
	if (!app.$vsf.$magento.config.state.getPermissions()) {
		error({ statusCode: 404 });
	}

	const userPermissions = app.$vsf.$magento.config.state.getPermissions();

	const permissions = route.meta.map((meta) => {
		if (meta.permissions) {
			return userPermissions.some(permission => meta.permissions.includes(permission.code));
		}
	});

	if (!permissions.includes(true)) {
		error({ statusCode: 404 });
	}
}

export default middleware;