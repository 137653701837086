import { References, AgnosticApplication } from '../types';
import { AgnosticPagination } from '~/composables/types';

export const getSlug = (references: References): string => {
	let url = references?.url_key ? `/references/${references.url_key}-${references.entity_id}` : '';
	return url;
};

export const getFullName = (references: References): string => {
	let name = references.name;

	if (references.country) {
		name = name + ', ' + references.country;
	}

	return name;
};

export const getHorizontalImage = (references: References): string => {
	if (!references || !references.image.horizontal) {
		return null;
	}

	return references.image.horizontal;
};

export const getVerticalImage = (references: References): string => {
	if (!references || !references.image.vertical) {
		return null;
	}

	return references.image.vertical;
};

export const getWideImage = (references: References): string => {
	if (!references || !references.image.wide) {
		return null;
	}

	return references.image.wide;
};

const getPagination = (references: any): AgnosticPagination => ({
	currentPage: references?.pageInfo?.currentPage || 1,
	totalPages: references?.pageInfo?.endPage || 1,
	totalItems: references?.total_count || 0,
	itemsPerPage: references?.pageInfo?.pageSize || 10,
	pageOptions: [10, 50, 100],
});

const referencesGetters = {
	getSlug,
	getPagination,
	getHorizontalImage,
	getVerticalImage,
	getWideImage,
	getFullName,
};

export default referencesGetters;
