import { Middleware } from '@nuxt/types';
/*
    if guest, redirect to members login on staging server
*/
const middleware: Middleware = ({ route, app, redirect }) => {
    if (process.env.STORE_ENV == 'staging' && !app.$vsf.$magento.config.state.getCustomerToken()) {
        if (!route.path.includes('login')) {
            const login = app.localePath('/login');
            redirect(login);
        }
    }
};

export default middleware;