module.exports = {
	productCard: {
		width: 216,
		height: 268,
	},
	productCardHorizontal: {
		width: 140,
		height: 200,
	},
	cartItem: {
		width: 100,
		height: 100,
	},
};
