import { useRoute, useContext, ref } from '@nuxtjs/composition-api';
import { Logger } from '~/helpers/logger';
import { Maybe, RouteQuery } from '~/modules/GraphQL/types';

const useMagentoRoute = () => {
	const route = useRoute();
	const { error: nuxtError, app } = useContext();
	const context = app.$vsf;
	const { path } = route.value;
	const loading = ref(false);
	const error = ref({
		search: null,
	});
    
	const search = async (isProductConfigurator = false): Promise<Maybe<RouteQuery>> => {
		loading.value = true;
		let results = null;

		try {
			const clearUrl = isProductConfigurator 
				? path.replace(/\/([a-z]+\/(product-configurator)\/)/gi, '')
				: path.replace(
					/\/([a-z]+\/(products)\/)/gi,
					''
				).replace(/(\d{1,5})-/i, '');

			Logger.debug('[Magento] Find information based on URL', { clearUrl });
			const { data } = await context.$magento.api.route(clearUrl);
			results = data.route;

			if (!results) nuxtError({ statusCode: 404 });

			Logger.debug('[Result]:', { results });
		} catch (err) {
			error.value.search = err;

			Logger.error('useMagentoRoute/search', err);
		} finally {
			loading.value = false;
		}

		return results;
	};

	return {
		path,
		error,
		search,
		loading,
	};
};

export default useMagentoRoute;