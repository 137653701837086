import useApi from '~/composables/useApi';
import { CatalogPdf } from './types';
import catalogPdf from './catalogPdf.gql';
import { ref } from '@nuxtjs/composition-api';

export const useCatalogPdf = () => {
	const { query } = useApi();
	const loading = ref(false);
    
	const getCatalogPdf = async (): Promise<CatalogPdf[]|null> => {
		loading.value = true;

		const { data } = await query<{ catalogPdf: CatalogPdf[] }>(catalogPdf);
		const items = data?.catalogPdf ?? [];
		loading.value = false;
        
		return items;
	};

	return {
		getCatalogPdf,
		loading,
	};
}

export default useCatalogPdf;
