import Vue from 'vue'
import VueGtag from 'vue-gtag'
import { pageview } from 'vue-gtag'

export default function(context, inject) {
	if (process.env.NODE_ENV === 'production') {
		Vue.use(VueGtag, {
			pageTrackerScreenviewEnabled: true,
			pageTrackerUseFullPath: true,
			config: {
				id: 'G-VTEE7BFQ3C',
			},
			enabled: true,
		})

		const router = context.app.router

		router.afterEach(to => {
			pageview(to.fullPath)
		})
	}
}