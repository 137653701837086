export const defaultConfig = {
	cookies: {
		currencyCookieName: 'vsf-currency',
		countryCookieName: 'vsf-country',
		localeCookieName: 'vsf-locale',
		cartCookieName: 'vsf-cart',
		customerCookieName: 'vsf-customer',
		storeCookieName: 'vsf-store',
		messageCookieName: 'vsf-message',
		memberCookieName: 'vsf-member',
        selectedCompanyCookieName: 'vsf-selected-company',
		isAcceptedTermsCookieName: 'vsf-is-accepted-terms',
		canApproveMemberCookieName: 'vsf-can-approve-member',
		isCountrySelectedCookieName: 'vsf-is-country-selected',
		isRememberMeCookieName: 'vsf-is-remember-me',
		permissionsCookieName: 'vsf-permissions',
	},
	locale: undefined,
	country: undefined,
	currency: undefined,
} as const;
