import { gql } from 'graphql-request';

export default gql`
    query ($filter: LabelsFilterInput) {
        labels(filter: $filter, pageSize: 100, currentPage: 1) {
            total_count
            pageInfo {
                currentPage
                endPage
                hasNextPage
                hasPreviousPage
                pageSize
                startPage
            }
            items {
                label_id
                title
                image
                type
                reference
            }
        }
    }
`;