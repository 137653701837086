import { gql } from 'graphql-request';

export default gql`
    query($filter: AcademyListFilterInput) {
        academyList(filter: $filter) {
            items {
                id
                name
                slug
                url
                description
                category
                image
                files {
                    name
                    path
                }
                created_at
            }
        }
    }
`;