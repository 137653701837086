import { gql } from 'graphql-request';

export default gql`
    mutation removeCart($cart_id: String!) {
        removeCart(cart_id: $cart_id) {
            items {
                id
                bee_customer_id
                project
                is_active
            }
        }
    }
`;