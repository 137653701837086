import { gql } from 'graphql-request';

export default gql`
    query erpCompanies {
        erpCompanies {
            items {
                id
                name
            }
        }
    }
`;
