import { CategoryGetters } from '~/getters/types';
import { buildCategoryTree } from '~/modules/catalog/category/helpers';
import { AgnosticCategoryTree, AgnosticBreadcrumb, Category } from '~/composables/types';

export const getTree = (category: Category): AgnosticCategoryTree => buildCategoryTree(category, '');

export const getCategoryTree = (
	category: Category,
	currentCategory: string = '',
	withProducts = false,
): AgnosticCategoryTree | null => (
	category
		? buildCategoryTree(category, currentCategory, withProducts)
		: null
);

export const getCategoryBreadcrumbs = (category: any): AgnosticBreadcrumb[] => {
	let breadcrumbs = [];

	if (!category) {
		return [];
	}

	if (Array.isArray(category?.breadcrumbs)) {
		breadcrumbs = category.breadcrumbs.map((breadcrumb) => ({
			text: breadcrumb.category_name,
			link: `/products/${breadcrumb.category_url_path}${category.url_suffix || ''}`,
		} as AgnosticBreadcrumb));
	}

	breadcrumbs.push({
		text: category.name,
		link: `/products/${category.url_path}${category.url_suffix || ''}`,
	} as AgnosticBreadcrumb);

	return breadcrumbs;
};

const categoryGetters: CategoryGetters<Category> = {
	getTree,
	getBreadcrumbs: getCategoryBreadcrumbs,
	getCategoryTree,
};

export default categoryGetters;
