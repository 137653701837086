import { Assortments } from '~/modules/vizulo/assortment/types';
import useApi from '~/composables/useApi';
import assortmentGql from '~/modules/vizulo/assortment/assortment.gql';
import { Logger } from '~/helpers/logger';
import { ref } from '@nuxtjs/composition-api';

export const useAssortment = () => {
	const { query } = useApi();
	const loading = ref(false);
	const assortment = ref({});
    
	const getAssortment = async (searchParams): Promise<Assortments> => {
		loading.value = true;
		const { data } = await query<{ assortment: Assortments }>(assortmentGql, searchParams);
		const assortmentData = data?.assortment;

		assortment.value = assortmentData;

		loading.value = false
        
		return {
			data: assortmentData?.data,
			filtered_columns: assortmentData?.filtered_columns
		}
	}

	return {
		loading,
		getAssortment,
		assortment
	}
}

export default useAssortment;