import { gql } from 'graphql-request';

export default gql`
    query articlesWithFiveYearWarranty(
        $filter: ArticlesWithFiveYearWarrantyInput
    ) {
        articlesWithFiveYearWarranty(filter: $filter) {
            articles
        }
    }
`;