import useApi from '~/composables/useApi';
import customers from './customers.gql';
import setVizuloManager from './setVizuloManager.gql';
import approveCustomer from './approveCustomer.gql';
import priceListFiles from './priceListFiles.gql';
import priceLists from './priceLists.gql';
import changeLog from './changeLog.gql';
import permissions from './permissions.gql';
import setMemberPermissions from './setMemberPermissions.gql';
import inStock from './inStock.gql';
import { Logger } from '~/helpers/logger';
import { ref } from '@nuxtjs/composition-api';
import { Customer, Maybe, DataTableSearchParams } from '~/modules/GraphQL/types';
import { generatePermissionsFormData } from './helpers/permissionsFormDataGenerator';
import {
	CustomersSearchParams,
	PriceListFileSearchParams,
	Members,
	PriceLists,
	PriceListFiles,
	ApproveCustomerSearchParams,
	ApproveCustomerOutput,
	ChangeLogOutput,
	ChangeLog,
	PermissionsOutput,
	Company,
	Member,
	Permission,
	MemberPermissionsInput,
	VizuloManagerInput,
	VizuloManagerOutput,
	InStockOutput,
} from './types';

export const useMember = () => {
	const { query, mutate } = useApi();
	const loading = ref(false);
	const priceListLoading = ref(false);
	const permissionsLoading = ref(false);
	const inStockLoading = ref(false);

	const getPermissions = async (): Promise<PermissionsOutput> => {
		permissionsLoading.value = true;

		const { data } = await query<{ permissions: PermissionsOutput }>(permissions);
		const permission = data?.permissions?.permissions ?? [];
		const member_groups = data?.permissions?.member_groups ?? [];
		const companies = data?.permissions?.companies ?? [];
		const members = data?.permissions?.members ?? [];

		permissionsLoading.value = false;

		return {
			permissions: permission,
			member_groups: member_groups,
			companies: companies,
			members: members
		};
	};

	const setPermissions = async (permissions: MemberPermissionsInput): Promise<PermissionsOutput> => {
		permissionsLoading.value = true;
		// eslint-disable-next-line no-underscore-dangle
		const input = generatePermissionsFormData(permissions);

		const { data } = await mutate<{ setMemberPermissions: PermissionsOutput }>(setMemberPermissions, { MemberPermissionsInput: input });

		const permission = data?.setMemberPermissions?.permissions ?? [];
		const member_groups = data?.setMemberPermissions?.member_groups ?? [];
		const companies = data?.setMemberPermissions?.companies ?? [];
		const members = data?.setMemberPermissions?.members ?? [];

		permissionsLoading.value = false;

		return {
			permissions: permission,
			member_groups: member_groups,
			companies: companies,
			members: members
		};
	};

	const getMembers = async (searchParams: CustomersSearchParams): Promise<Maybe<Array<Members>>> => {
		loading.value = true;

		const { data } = await query<{ customers: Members }>(customers, searchParams);
		const items = data.customers?.items ?? [];

		loading.value = false;

		return items;
	};

	const approveMember = async (searchParams: ApproveCustomerSearchParams): Promise<ApproveCustomerOutput> => {
		loading.value = true;

		const { data } = await query<{ approveCustomer: ApproveCustomerOutput }>(approveCustomer, searchParams);
		const item = data?.approveCustomer;

		loading.value = false;

		return {
			status: item.status,
		};
	};

	const setManager = async (company_id: string, customer_id: string, enabled: boolean): Promise<VizuloManagerOutput> => {
		loading.value = true;

		const input = {
			company_id: company_id,
			customer_id: customer_id,
			enabled: enabled
		} as VizuloManagerInput;

		const { data } = await mutate<{ setVizuloManager: VizuloManagerOutput }>(setVizuloManager, { VizuloManagerInput: input });

		const status = data?.setVizuloManager?.status ?? false;

		loading.value = false;

		return {
			status: status
		};
	};

	const getFiles = async (searchParams: PriceListFileSearchParams): Promise<Maybe<PriceListFiles>> => {
		loading.value = true;

		const { data } = await query<{ priceListFiles: PriceListFiles }>(priceListFiles, searchParams);
		const items = data?.priceListFiles;

		loading.value = false;

		return  {
			data: items.data ?? []
		};
	};

	const getPriceList = async (params): Promise<Maybe<PriceLists>> => {
		const searchParams: DataTableSearchParams = {
			filter: {
				data_filter: {
					eq: JSON.stringify(params)
				}
			}
		};

		priceListLoading.value = true;

		const { data } = await query<{ priceLists: PriceLists }>(priceLists, searchParams);

		priceListLoading.value = false;

		const items = data.priceLists;

		return {
			product: items.product,
			total: items.total,
			page: items.page,
			per_page: items.per_page,
			description: items.description,
			data: items.data,
			filtered_columns: items.filtered_columns,
		};
	};

	const getInStockList = async (params): Promise<Maybe<InStockOutput>> => {
		const searchParams: DataTableSearchParams = {
			filter: {
				data_filter: {
					eq: JSON.stringify(params)
				}
			}
		};

		inStockLoading.value = true;

		const { data } = await query<{ inStock: InStockOutput }>(inStock, searchParams);

		inStockLoading.value = false;

		return data?.inStock;
	};

	const getChangeLog = async (): Promise<Maybe<ChangeLogOutput>> => {
		loading.value = true;

		const { data } = await query<{ changeLog: ChangeLogOutput }>(changeLog);
		const items = data?.changeLog?.items ?? [];

		loading.value = false;

		return {
			items
		};
	};

	return {
		getMembers,
		getFiles,
		getPriceList,
		loading,
		priceListLoading,
		approveMember,
		getChangeLog,
		permissionsLoading,
		getPermissions,
		setPermissions,
		setManager,
		getInStockList,
		inStockLoading,
	};
}

export default useMember;