import { computed, reactive, ref } from '@nuxtjs/composition-api';
import { StateInterface, UseUiStateInterface } from '~/composables/useUiState/useUiState';

const state = reactive<StateInterface>({
	isNavigationSidebarOpen: false,
	isNavigationStoreSwitcherOpen: false,
	isNavigationSearchOpen: false,
	isWishlistSidebarOpen: false,
	isLoginModalOpen: false,
	isCategoryGridView: true,
	isWishlistGridView: true,
	isRefreshCart: false,
	isFilterOpen: false,
	isMobileMenuOpen: false,
	isMobile: false,
	isCountrySelectorModalOpen: false
});

export const useUiState = (): UseUiStateInterface => {

	const isMobileMenuOpen = computed(() => state.isMobileMenuOpen);

	const toggleMobileMenu = () => {
		state.isMobileMenuOpen = !state.isMobileMenuOpen;
	};

	const isNavigationStoreSwitcherOpen = computed(() => state.isNavigationStoreSwitcherOpen);

	const toggleNavigationStoreSwitcher = () => {
		if (state.isNavigationSearchOpen) {
			toggleNavigationSearch();
		}

		if (state.isNavigationSidebarOpen) {
			toggleNavigationSidebar();
		}

		state.isNavigationStoreSwitcherOpen = !state.isNavigationStoreSwitcherOpen;
	};

	const isNavigationSearchOpen = computed(() => state.isNavigationSearchOpen);

	const toggleNavigationSearch = () => {
		if (state.isNavigationStoreSwitcherOpen) {
			toggleNavigationStoreSwitcher();
		}

		if (state.isNavigationSidebarOpen) {
			toggleNavigationSidebar();
		}

		state.isNavigationSearchOpen = !state.isNavigationSearchOpen;
	};

	const isNavigationSidebarOpen = computed(() => state.isNavigationSidebarOpen);

	const toggleNavigationSidebar = () => {
		if (state.isNavigationStoreSwitcherOpen) {
			toggleNavigationStoreSwitcher();
		}

		if (state.isNavigationSearchOpen) {
			toggleNavigationSearch();
		}

		state.isNavigationSidebarOpen = !state.isNavigationSidebarOpen;
	};

	const isWishlistSidebarOpen = computed(() => state.isWishlistSidebarOpen);

	const toggleWishlistSidebar = () => {
		state.isWishlistSidebarOpen = !state.isWishlistSidebarOpen;
	};

	const isLoginModalOpen = computed(() => state.isLoginModalOpen);

	const toggleLoginModal = () => {
		state.isLoginModalOpen = !state.isLoginModalOpen;
	};

	const isCategoryGridView = computed(() => state.isCategoryGridView);

	const isWishlistGridView = computed(() => state.isWishlistGridView);

	const changeToCategoryGridView = () => {
		state.isCategoryGridView = true;
	};

	const changeToCategoryListView = () => {
		state.isCategoryGridView = false;
	};

	const changeToWishlistGridView = () => {
		state.isWishlistGridView = true;
	};

	const changeToWishlistListView = () => {
		state.isWishlistGridView = false;
	};

	const refreshCart = (status) => {
		state.isRefreshCart = status;
	};

	const isRefreshCart = computed(() => state.isRefreshCart);

	const isFilterOpen = computed(() => state.isFilterOpen);

	const toggleFilter = () => {
		state.isFilterOpen = !state.isFilterOpen;
	};

	const isMobile = computed(() => state.isMobile);

	const width = ref(process.browser ? window.innerWidth : '');

	const toggleMobileState = () => {
		width.value = window.innerWidth;
		width.value < 992 ? state.isMobile = true : state.isMobile = false;
	}

	const isCountrySelectorModalOpen = computed(() => state.isCountrySelectorModalOpen);

	const toggleCountrySelectorModal = () => {
		state.isCountrySelectorModalOpen = !state.isCountrySelectorModalOpen;
	}

	return {
		isNavigationSearchOpen,
		isNavigationStoreSwitcherOpen,
		isNavigationSidebarOpen,
		isWishlistSidebarOpen,
		isLoginModalOpen,
		isCategoryGridView,
		isWishlistGridView,
		isFilterOpen,
		isMobileMenuOpen,
		toggleNavigationSidebar,
		toggleNavigationSearch,
		toggleNavigationStoreSwitcher,
		toggleWishlistSidebar,
		toggleLoginModal,
		changeToCategoryGridView,
		changeToCategoryListView,
		changeToWishlistGridView,
		changeToWishlistListView,
		refreshCart,
		isRefreshCart,
		toggleFilter,
		toggleMobileMenu,
		isMobile,
		toggleMobileState,
		isCountrySelectorModalOpen,
		toggleCountrySelectorModal
	};
};

export default useUiState;
