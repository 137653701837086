import { Middleware } from '@nuxt/types';

const middleware: Middleware = ({ route, app, error }) => {
    const userPermissions = app.$vsf.$magento.config.state.getPermissions();

    if (!userPermissions.find(el => el.code == 'add edit order' && el.enabled)) {
        error({ statusCode: 404 });
    }
}

export default middleware;