import { gql } from 'graphql-request';

export default gql`
    query inStock(
        $filter: DataTableFilterInput
    ) {
        inStock(filter: $filter) {
            total
            page
            per_page
            data {
                article
                name
                quantity
                price
                description
            }
            filtered_columns {
                label
                field
                attribute_code
                abbreviation
                hidden
                description
                values {
                    uid
                    label
                    is_active
                    url
                    description
                    relation {
                        type
                        value
                    }
                }
                min
                max
                step
            }
        }
    }
`;