import { Ref, ref, ssrRef, useContext } from '@nuxtjs/composition-api';
import { AgnosticFacetSearchParams, ProductsSearchParams, ComposableFunctionArgs } from '~/composables/types';
import { Logger } from '~/helpers/logger';
import {
	FacetSearchResult, UseFacet, UseFacetErrors, GetProductSearchParams,
} from './useFacet';

const availableSortingOptions = [
	{
		label: 'Sort: Default',
		value: '',
	},
	{
		label: 'Sort: Position ASC',
		value: 'position_ASC',
	},
	{
		label: 'Sort: Position DESC',
		value: 'position_DESC',
	},
	{
		label: 'Sort: Name A-Z',
		value: 'name_ASC',
	},
	{
		label: 'Sort: Name Z-A',
		value: 'name_DESC',
	},
	{
		label: 'Sort: Price from low to high',
		value: 'price_ASC',
	}, {
		label: 'Sort: Price from high to low',
		value: 'price_DESC',
	},
];

const constructFilterObject = (inputFilters: Object) => {
	const filter = {};

	Object.keys(inputFilters).forEach((key) => {
		if (typeof inputFilters[key] === 'string' && key !== 'category_key') {
			filter[key] = { in: [inputFilters[key]] };
			return;
		}

		filter[key] = { in: inputFilters[key] };
	});

	return filter;
};

const constructSortObject = (sortData: string) => {
	const baseData = sortData.split(/_/gi);

	return baseData.length > 0 ? Object.fromEntries([baseData]) : {};
};

export const useFacet = (): UseFacet => {
	const { app } = useContext();
	const loading = ref(false);
	const result: Ref<FacetSearchResult<any>> = ref({ data: null, input: null });
	const error: Ref<UseFacetErrors> = ref({
		search: null,
	});

	const search = async (params?: ComposableFunctionArgs<AgnosticFacetSearchParams>) => {
		Logger.debug('useFacet/search', params);

		result.value.input = params;
		try {
			loading.value = true;

			const itemsPerPage = (params.itemsPerPage) ? params.itemsPerPage : 20;
			const inputFilters = (params.filters) ? params.filters : {};
			let categoryId = {};

			// uid
			if (params.categoryId) {
    			categoryId = {
    				category_uid: {
    					...(Array.isArray(params.categoryId)
    						? { in: params.categoryId }
    						: { eq: params.categoryId }),
    				},
    			};
			}

			// real category id
			if (params.catId) {
				categoryId = {
					category_id: {
						...(Array.isArray(params.catId)
							? { in: params.catId }
							: { eq: params.catId }),
					},
				};
			}

			const productParams: ProductsSearchParams = {
				filter: {
					...categoryId,
					...constructFilterObject({
						...inputFilters,
					}),
				},
				perPage: itemsPerPage,
				offset: (params.page - 1) * itemsPerPage,
				page: params.page,
				search: (params.term) ? params.term : '',
				sort: constructSortObject(params.sort || ''),
			};

			const productSearchParams: GetProductSearchParams = {
				pageSize: productParams.perPage,
				search: productParams.search,
				filter: productParams.filter,
				sort: productParams.sort,
				currentPage: productParams.page,
			};

			// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
			const { data } = await app.context.$vsf.$magento.api.products(productSearchParams, params?.customQuery || { products: 'products' });

			Logger.debug('[Result]:', { data });

			result.value.data = {
				items: data?.products?.items || [],
				total: data?.products?.total_count,
				availableFilters: data?.products?.aggregations,
				category: { id: params.categoryId },
				availableSortingOptions,
				perPageOptions: [10, 20, 50],
				itemsPerPage,
			};

			error.value.search = null;
		} catch (err) {
			error.value.search = err;
			Logger.error('useFacet/search', err);
		} finally {
			loading.value = false;
		}
	};

	return {
		result,
		loading,
		error,
		search,
	};
};

export default useFacet;
