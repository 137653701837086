import { Context } from '@nuxt/types';
import { LocaleObject } from 'nuxt-i18n';

const findLocaleBasedOnMagentoStoreCode = (
    storeCode: string,
    locales: Array<string | LocaleObject>
) => locales.find((locale) => ((typeof locale === 'object' ? locale.code : locale) === storeCode));

const findCurrencyBasedOnMagentoStoreCode = (
    storeCode: string,
    locales: Array<string | LocaleObject>
): string => {
	const match = locales.find((locale) => typeof locale === 'object' && locale.code === storeCode) as LocaleObject | undefined;
	return match?.defaultCurrency;
};

export default ({ app, route }: Context) => {
	return app.$vsf.$magento.client.interceptors.request.use(async (request) => {
		const {
			$vsf: { $magento: { config: { state } } },
			i18n
		} = app;

		const currentStoreCode = state.getStore() ?? route.path.split('/')[1]; // localhost:3000/default
		const shouldSetDefaultLocale = !currentStoreCode || !findLocaleBasedOnMagentoStoreCode(currentStoreCode, i18n.locales);

		if (shouldSetDefaultLocale) {
			await i18n.setLocale(i18n.defaultLocale);
		}

		const i18nCurrentLocaleCode = i18n.locale;
		const magentoLocale = state.getLocale();
		const shouldLocaleBeRefreshed = i18nCurrentLocaleCode !== magentoLocale;

		if (shouldLocaleBeRefreshed) {
			const locale = !route.path.split('/')[1]
				? magentoLocale ?? i18n.defaultLocale
				: i18nCurrentLocaleCode;

			const currency = findCurrencyBasedOnMagentoStoreCode(locale, i18n.locales);

			state.setCurrency(currency);
			state.setStore(locale);
			state.setLocale(locale);

			await i18n.setLocale(locale);
		}

		return request;
	});
};
