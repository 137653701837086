import { gql } from 'graphql-request';

export default gql`query($filter: CustomerAddressesFilterInput) {
    customerAddresses(filter: $filter) {
        city
        country_code
        default_billing
        default_shipping
        extension_attributes {
            attribute_code
            value
        }
        id
        company
        firstname
        lastname
        postcode
        prefix
        region {
            region_code
            region_id
            region
        }
        street
        suffix
        telephone
        vat_id
    }
}`;