import { gql } from 'graphql-request';

export default gql`
    query productByData($filter: CustomProductFilterInput) {
        productByData(filter: $filter) {
            uid
            sku
            abbreviation
            assortiment
            name
            page_layout
            position
            new_from_date
            new_to_date
            attribute_set
            access_configurator_from
            bee_product_id
            bee_product_name
            stock_status
            only_x_left_in_stock
            url_key
            url_rewrites {
                url
            }
            categories {
                uid
                name
                level
                url_suffix
                url_path
                breadcrumbs {
                    category_name,
                    category_url_path
                }
            }
            thumbnail {
                url
                disabled
                label
                tags
            }
            small_image {
                url
                disabled
                label
                tags
            }
            image {
                url
                disabled
                label
                tags
            }
            hero_image {
                url
                disabled
                label
                tags
            }
            media_gallery {
                url
                position
                disabled
                label
                tags
            }
            meta_description
            meta_keyword
            meta_title
            description {
                html
            }
            short_description {
                html
            }
            specification_description
            notes
            video
            top_image
            image_360
            hero
            product_use
            product_type
            family
            product_family
            luminous_efficacy_min
            luminous_efficacy_max
            luminous_flux_min
            luminous_flux_max
            power_min
            power_max
            product_colors {
                name
                url
            }
            accessory_groups {
                id
                name
                description
            }
            related_products {
                __typename
                sku
                name
                url_key
                url_rewrites {
                    url
                }
                small_image {
                    url
                    position
                    disabled
                    label
                }
                categories {
                    uid
                    name
                    level
                    url_suffix
                    url_path
                }
            }
            feature_categories {
                items {
                    id
                    name
                    features {
                        items {
                            entity_id
                            category
                            name
                            content
                            status
                            image
                            gallery
                            products
                            order_no
                            video
                        }
                    }
                }
            }
            options_container
            special_to_date
        }
    }
`;
