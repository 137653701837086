import { useContext } from '@nuxtjs/composition-api';

export type FetchPolicy = 'cache-first' | 'network-only' | 'cache-only' | 'no-cache' | 'standby';

export type Variables = {
	[key: string]: any;
};

export type Error = {
	message: string;
	locations?: {
		line: number;
		column: number;
	}[];
	path?: string[];
	extensions?: any;
};

export type Request = <DATA, VARIABLES extends Variables = Variables>(
	request: string,
	variables?: VARIABLES,
	fetchPolicy?: FetchPolicy,
) => Promise<{ data: DATA, errors: Error[] }>;


export const useApi = () => {
	const { app } = useContext();

	/**
	 * Update composable files that uses this method
	 * by type-casting the data type
	 *
	 * Example: const { data } = await query<{ catalogPdf: CatalogPdf[] }>(catalogPdf);
	 */
	// @ts-ignore
	const query: Request = async (request, variables = null) => {        
		const { data, errors } = await app.$vsf.$magento.api.customQuery({ query: request, queryVariables: variables });

		return { data, errors };
	}

	// @ts-ignore
	const mutate: Request = async (request, variables = null) => {
		const { data, errors } = await app.$vsf.$magento.api.customMutation({ mutation: request, mutationVariables: variables });

		return { data, errors };
	}

	return {
		query,
		mutate,
	};
};

export default useApi;
