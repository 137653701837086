import type { Plugin } from '@nuxt/types';
import type { AxiosResponse } from 'axios';
import { useCustomerStore } from '~/stores/customer';
import type { UiNotification } from '~/composables/useUiNotification';

export const hasGraphqlAuthorizationError = (res: AxiosResponse) => res?.data?.errors
	?.some((error) => error?.extensions?.category === 'graphql-authorization') ?? false;

const plugin: Plugin = ({ app, $pinia, redirect }) => {

	app.context.$vsf.$magento.client.interceptors.response.use((res) => {
		if (!hasGraphqlAuthorizationError(res)) {
			return res;
		}

		const apiState = app.context.$vsf.$magento.config.state;
        const customerStore = useCustomerStore($pinia);

		apiState.removeCustomerToken();
		apiState.removeCartId();
		apiState.removeIsMember();
		apiState.removeIsAcceptedTerms();
        apiState.removeCanApproveMember();
		apiState.removeSelectedCompany();
		apiState.removePermissions();

		customerStore.setIsLoggedIn(false);

		process.server ? redirect(app.localePath('/login')) : window.location.href = app.localePath('/login');

		return false;
	});
};

export default plugin;
