import { gql } from 'graphql-request';

export default gql`
    query customers(
        $filter: CustomersFilterInput,
    ) {
        customers(filter: $filter) {
            items {
                bee_customer_id
                email
                company_name
                bee_company_name
                firstname
                lastname
            }
        }
    }
`;