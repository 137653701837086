import { gql } from 'graphql-request';

export default gql`
    query changeLog {
        changeLog {
            items {
                id
                description
                platform
                category
                created_at
            }
        }
    }
`;