import useApi from '~/composables/useApi';
import referencesQuery from './references.gql';
import { References, PageInfo, ReferencesFilterInput } from './types';
import { Logger } from '~/helpers/logger';
import { ref } from '@nuxtjs/composition-api';

interface ReferencesOutput {
    total_count: number,
    items: Array<References>,
    pageInfo: PageInfo,
}

export const useReferences = () => {
    const { query } = useApi();
    const loadingReferences = ref(false);
    const loadingItem = ref(false);

    const getReferences = async (searchParams): Promise<ReferencesOutput> => {
        // Logger.debug(`References/searchParams`, searchParams);
        loadingReferences.value = true;
        const { data } = await query<{ references: ReferencesOutput }>(referencesQuery, searchParams);
        const referencesData = data?.references;
        // Logger.debug(`References/Response`, referencesData);

        loadingReferences.value = false;

        return {
            items: referencesData.items ?? [],
            total_count: referencesData.total_count,
            pageInfo: referencesData.pageInfo,
        };
    };

    const getItem = async (searchParams: ReferencesFilterInput): Promise<References> => {
        loadingItem.value = true;

        const { data } = await query<{ references: ReferencesOutput }>(referencesQuery, searchParams);
        const referencesData = data?.references;

        // Logger.debug(`References/Response`, referencesData);

        const entity_id = referencesData?.items[0]?.entity_id;
        const name = referencesData?.items[0]?.name;
        const content = referencesData?.items[0]?.content;
        const application = referencesData?.items[0]?.application;
        const country_id = referencesData?.items[0]?.country_id;
        const country = referencesData?.items[0]?.country;
        const image = referencesData?.items[0]?.image;
        const gallery = referencesData?.items[0]?.gallery;
        const products = referencesData?.items[0]?.products;
        const url_key = referencesData?.items[0]?.url_key;
        const top = referencesData?.items[0]?.top;
        const map = referencesData?.items[0]?.map;
        const created_at = referencesData?.items[0]?.created_at;

        loadingItem.value = false;

        return {
            entity_id,
            name,
            content,
            application,
            country_id,
            country,
            image,
            gallery,
            products,
            url_key,
            top,
            map,
            created_at,
        };
    };

    return {
        getReferences,
        getItem,
        loadingReferences,
        loadingItem,
    };
}

export default useReferences;