import { useContext } from '@nuxtjs/composition-api';
import axios, { ResponseType } from 'axios';

const useVizulo = () => {
	/**
	 * Get Vizulo API url.
	 *
	 * @return {string}
	 */
	const getApiUrl = () => {
		const { $config } = useContext();
		return $config.vizuloApi;
	}

	const getApiVizuloStorage = () => {
		const { $config } = useContext();
		return $config.apiVizuloStorage;
	}

	const downloadFile = async (url, fileName, headers = {}, responseType: ResponseType = 'arraybuffer') => {
		await axios.get(url, {
			headers,
			responseType
		}).then(res => {
			let blob = new Blob([res.data], { type: res.headers['content-type'] });
			let link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = fileName;
			link.click();
		});
	}

	return {
		getApiUrl,
		getApiVizuloStorage,
		downloadFile,
	};
};

export default useVizulo;