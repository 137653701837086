import { Middleware } from '@nuxt/types';
/*
    checks if customer has bee_customer_id
*/
const middleware : Middleware = (context) => {
	if (!context.app.$vsf.$magento.config.state.getIsMember()) {
		const login = context.app.localePath('/members');
		context.redirect(login);
	}
};

export default middleware;