import { Ref, ref, computed, useContext } from '@nuxtjs/composition-api';
import mask from '~/composables/utils/mask';
import { Logger } from '~/helpers/logger';
import { useCustomerStore } from '~/stores/customer';
import { generateUserData } from '~/helpers/customer/userDataGenerator';
import { UseUser } from '~/composables/useUser/useUser';
import { useCart, useUiNotification } from '~/composables';
import { useRouter } from '@nuxtjs/composition-api';

export const useUser = (): UseUser => {
	const customerStore = useCustomerStore();
	const { app } = useContext();
	const { cart, setCart } = useCart();

	const loading: Ref<boolean> = ref(false);
    const beeCustomerId: Ref<string> = ref('0');
	const selectedCompany: Ref<string> = ref('0');
	const isAdmin: Ref<boolean> = ref(false);
	const canApproveMember: Ref<boolean> = ref(false);
	const isAuthenticated = computed(() => Boolean(customerStore.user?.firstname));
    const { send: sendNotification } = useUiNotification();

	const errorsFactory = () => ({
		updateUser: null,
		register: null,
		login: null,
		loginAsCustomer: null,
		logout: null,
		changePassword: null,
        load: null,
		hasPermission: null
	});
	const error: Ref = ref(errorsFactory());

	const setUser = (newUser) => {
		customerStore.user = newUser;
		Logger.debug('useUserFactory.setUser', newUser);
	};

	const hasPermission = (permission: string): boolean => {
		return customerStore.user?.permissions?.find(c => {
			return c.code == permission;
		});
	};

	const resetErrorValue = () => {
		error.value = errorsFactory();
	};

	const updateCustomerEmail = async (credentials: { email: string, password: string }): Promise<void> => {
		const { errors } = await app.context.$vsf.$magento.api.updateCustomerEmail(credentials);

		if (errors) {
			throw errors.map((e) => e.message).join(',');
		}
	};

	// eslint-disable-next-line consistent-return
	const updateUser = async ({ user: providedUser, customQuery = { updateCustomer: 'updateCustomer' } }) => {
		Logger.debug('[Magento] Update user information', { providedUser, customQuery });
		resetErrorValue();

		try {
			loading.value = true;
			const { email: oldEmail } = customerStore.user;
			const { email, password, ...updateData } = providedUser;

			const userData = generateUserData(updateData);

			if (email && email !== oldEmail) {
				await updateCustomerEmail({
					email,
					password
				});
			}

			const { data, errors } = await app.context.$vsf.$magento.api.updateCustomer(userData);
			Logger.debug('[Result]:', { data });

			if (errors) {
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
				Logger.error(errors.map((e) => e.message).join(','));
				error.value.updateUser = errors.map((e) => e.message).join(',');
			}

			customerStore.user = data?.updateCustomerV2?.customer || {};
			error.value.updateUser = null;


		} catch (err) {
			error.value.updateUser = err;
			Logger.error('useUser/updateUser', err);
		} finally {
			loading.value = false;
		}
	};

	const logout = async ({ customQuery = {} } = {}) => {
		Logger.debug('[Magento] useUserFactory.logout');
		resetErrorValue();

		try {
			const apiState = app.context.$vsf.$magento.config.state;

			if (customerStore.isLoggedIn) {
				await app.context.$vsf.$magento.api.revokeCustomerToken({ customQuery });

				apiState.setCustomerToken(null);
				apiState.setCartId(null);
				apiState.setIsMember(false);
				apiState.setIsAcceptedTerms(false);
				apiState.setCanApproveMember(false);
				apiState.setPermissions(false);
			}

			setCart(null);
			customerStore.setIsLoggedIn(false);
			error.value.logout = null;
			customerStore.user = null;
		} catch (err) {
			error.value.logout = err;
			Logger.error('useUser/logout', err);
		}
	};

	const load = async ({ customQuery } = { customQuery: { customer: 'customer' } }) => {
		Logger.debug('[Magento] useUser.load');
		resetErrorValue();

		try {
			loading.value = true;
			const apiState = app.context.$vsf.$magento.config.state;

			if (!apiState.getCustomerToken()) {
				return null;
			}
			try {
				const { data } = await app.context.$vsf.$magento.api.customer(customQuery);

				customerStore.setIsLoggedIn(true);
				Logger.debug('[Result]:', { data });

				customerStore.user = data?.customer ?? {};

				apiState.setIsMember(customerStore.user?.bee_customer_id > 0);
				apiState.setIsAcceptedTerms(!!customerStore.user?.is_accepted_terms);
				apiState.setCanApproveMember(customerStore.user?.can_approve_other_members);
				apiState.setPermissions(customerStore.user?.permissions);
                apiState.setSelectedCompany(customerStore.cart?.bee_customer_id ?? '0');
			} catch {
				// eslint-disable-next-line no-void
				// @ts-ignore
				await logout();
			}
			error.value.load = null;
		} catch (err) {
			error.value.load = err;
			Logger.error('useUser/load', err);
		} finally {
			loading.value = false;
		}

		return customerStore.user;
	};

	// eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
	const login = async ({ user: providedUser, customQuery = { generateCustomerToken: 'generateCustomerToken' } }) => {
		Logger.debug('[Magento] useUser.login', providedUser);
		resetErrorValue();

		try {
			loading.value = true;
			const apiState = app.context.$vsf.$magento.config.state;

			const { data, errors } = await app.context.$vsf.$magento.api.generateCustomerToken(
				{
					email: providedUser.username,
					password: providedUser.password,
					recaptchaToken: providedUser.recaptchaToken,
					remember_me: providedUser.rememberMe
				},
				customQuery || {}
			);

			Logger.debug('[Result]:', { data });

			if (errors && errors.length > 0) {
				const errorMessages = errors.map((e) => e.message).join(',');
				Logger.error(errorMessages);
				error.value.login = {
					message: errorMessages
				}
			}

			if (data?.generateCustomerTokenV2?.token) {
				customerStore.setIsLoggedIn(true);
				apiState.setCustomerToken(data.generateCustomerTokenV2.token);
				// merge existing cart with customer cart
				// todo: move this logic to separate method
				const currentCartId = apiState.getCartId();
				const cart = await app.context.$vsf.$magento.api.customerCart();
				const newCartId = cart.data.customerCart.id;

				if (newCartId && currentCartId && currentCartId !== newCartId) {
					const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts(
						{
							sourceCartId: currentCartId,
							destinationCartId: newCartId
						}
					);

					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					setCart(dataMergeCart.mergeCarts);

					apiState.setCartId(dataMergeCart.mergeCarts.id);
				} else {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					setCart(cart.data.customerCart);
				}

				error.value.login = null;
				customerStore.user = load();
			}
		} catch (err) {
			error.value.login = err;
			Logger.error('useUser/login', err);
		} finally {
			loading.value = false;
		}

		return customerStore.user;
	};

	// eslint-disable-next-line consistent-return
	const register = async ({ user: providedUser, customQuery }) => {
		Logger.debug('[Magento] useUser.register', providedUser);
		resetErrorValue();

		try {
			loading.value = true;

			const {
				email,
				password,
				recaptchaToken,
				...baseData
			} = generateUserData(providedUser);

			const { data, errors } = await app.context.$vsf.$magento.api.createCustomer(
				{
					email,
					password,
					recaptchaToken,
					...baseData
				},
				customQuery || {}
			);

			Logger.debug('[Result]:', { data });

			if (errors) {
				const errorMessages = errors.map((e) => e.message).join(',');
				Logger.error(errorMessages);
				error.value.register = {
					message: errorMessages
				}
			} else {
                if (data?.createCustomerV2.customer?.is_approved == 'approved') {
                    sendNotification({
                        id: Symbol('member_approved'),
                        message: 'Thank you for registering!',
                        type: 'success',
                        icon: 'check',
                        persist: false,
                        title: 'Member created',
                    });

                    customerStore.user = login({
                        user: {
                            username: email,
                            password: password
                        }
                    });
                }
            }
		} catch (err) {
			error.value.register = err;
			Logger.error('useUser/register', err);
		} finally {
			loading.value = false;
		}
	};

	// eslint-disable-next-line consistent-return
	const changePassword = async (params) => {
		Logger.debug('[Magento] useUser.changePassword', {
			currentPassword: mask(params.current),
			newPassword: mask(params.new)
		});
		resetErrorValue();

		try {
			loading.value = true;

			const { data, errors } = await app.context.$vsf.$magento.api.changeCustomerPassword({
				currentUser: customerStore.user,
				currentPassword: params.current,
				newPassword: params.new,
				customQuery: params.customQuery
			});

			if (errors) {
				Logger.error(errors.map((e) => e.message).join(','));
			}

			Logger.debug('[Result] ', { data });

			customerStore.user = data?.changeCustomerPassword;
			error.value.changePassword = null;
		} catch (err) {
			error.value.changePassword = err;
			Logger.error('useUser/changePassword', err);
		} finally {
			loading.value = false;
		}
	};

	// eslint-disable-next-line @typescript-eslint/require-await,no-empty-pattern
	const loginAsCustomer = async (params) => {
		Logger.debug('[Magento] useUser.loginAsCustomer', params);
		resetErrorValue();

		try {
			loading.value = true;
			const apiState = app.context.$vsf.$magento.config.state;

			const { data } = await app.context.$vsf.$magento.api.generateCustomerTokenAsAdmin(
				params
			);

			Logger.debug('[Result]:', { data });

			if (data?.generateCustomerTokenAsAdmin?.customer_token) {
				customerStore.setIsLoggedIn(true);
				apiState.setCustomerToken(data.generateCustomerTokenAsAdmin.customer_token);
				// merge existing cart with customer cart
				// todo: move this logic to separate method
				const currentCartId = apiState.getCartId();
				const cart = await app.context.$vsf.$magento.api.customerCart();
				const newCartId = cart.data.customerCart.id;

				if (newCartId && currentCartId && currentCartId !== newCartId) {
					const { data: dataMergeCart } = await app.context.$vsf.$magento.api.mergeCarts(
						{
							sourceCartId: currentCartId,
							destinationCartId: newCartId
						}
					);

					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					setCart(dataMergeCart.mergeCarts);

					apiState.setCartId(dataMergeCart.mergeCarts.id);
				} else {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					setCart(cart.data.customerCart);
				}

				error.value.loginAsCustomer = null;
				customerStore.user = load();
			}
		} catch (err) {
			error.value.loginAsCustomer = err;
			Logger.error('useUser/loginAsCustomer', err);
		} finally {
			loading.value = false;
		}

		return customerStore.user;
	};

	return {
		user: computed(() => customerStore.user),
		beeCustomerId: computed(() => String(customerStore.user?.bee_customer_id)),
		isAdmin: computed(() => Boolean(customerStore.user?.bee_customer_id == '77')),
		canApproveMember: computed(() => Boolean(customerStore.user?.can_approve_other_members)),
        selectedCompany,
		loading,
		isAuthenticated,
		error,
		setUser,
		updateUser,
		register,
		login,
		loginAsCustomer,
		logout,
		changePassword,
		load,
		hasPermission
	};
};

export default useUser;
